
import { IntlProvider } from 'react-intl';

export const formatPrice = (value, format, locale) => {
  // add . to fr-be prices, fake LU context
  if (locale === 'fr-be') {
    locale = 'fr-lu';
  }

  const intlProvider = new IntlProvider({ locale, messages: {} });
  const { intl } = intlProvider.state;

  return intl.formatNumber(value, format);
}
