const ENGINE = {
  values: {
    diesel: 'ice_diesel',
    petrol: 'ice_sessence',
    mhev: 'mhev',
    phev: 'phev',
    ev: 'ev',
    hev: 'hev',
  },

  ice: ['ice_diesel', 'ice_sessence'],
  electric: ['mhev', 'phev', 'ev', 'hev'],
};

export default ENGINE;
