import CONFIGURATOR from "../constants/configurator.constants";

export const sendFromConfigurator = payload => window.top.postMessage({
  source: CONFIGURATOR.source,
  direction: CONFIGURATOR.message_from,
  payload,
}, '*');
export const sendToConfigurator = payload => {
  const configurator = document.querySelector('#configurator');

  if (!configurator || !configurator.contentWindow) { return; }

  configurator.contentWindow.postMessage({
    source: CONFIGURATOR.source,
    direction: CONFIGURATOR.message_to,
    payload,
  }, '*');
}
