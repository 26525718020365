import React, { useState } from 'react';
import './eswitch.scss';
import { ReactComponent as ElectricLogo } from './go-electric-new.svg';

export default function Eswitch(props) {
  const {
    active,
    onClick
  } = props;

  const [ isActive, setActive ] = useState(active);

  const onSwitchClick = () => {
    onClick(!isActive);

    setActive(!isActive);
  };

  return (
    <div className={`eswitch${ isActive ? ' eswitch--active' : '' }`} onClick={onSwitchClick}>
      <div className="eswitch__logo">
        {/* <img src={logo} alt="Go electric" /> */}
        <ElectricLogo />
      </div>
      <div className="eswitch__toggle">
        <span></span>
      </div>
    </div>
  );
}
