import './showroom.scss';

import React, { useContext, useEffect, useRef, useState } from 'react';
import { getItemTemp, setItemTemp } from '../../../common/services/storage.service';

import Marzipano from 'marzipano';
import { FormattedMessage } from 'react-intl';
import CONFIG from '../../../common/config';
import ENGINE from '../../../common/constants/engine.constants';
import ROUTES from '../../../common/constants/routes.constants';
import STORAGE from '../../../common/constants/storage.constants';
import { KiaContext } from '../../../common/context/main.context';
import GTM from '../../../common/services/gtm.service';
import { formatPrice } from '../../../common/utils/price.utils';
import querystring from '../../../common/utils/querystring.utils';
import Button from '../../01_atoms/button/Button';
import Hotspot from '../../01_atoms/hotspot/Hotspot';
import Icon from '../../01_atoms/icons/Icon';
import Campaign from '../../02_molecules/campaign/Campaign';
import Engine from '../../02_molecules/engine/Engine';
import Eswitch from '../../02_molecules/eswitch/Eswitch';
import Loading from '../../02_molecules/loading/Loading';
import { PromoOffer } from '../../02_molecules/promo/PromoOffer';
import Page from '../../04_layouts/page/Page';
import badge from '../car/graphics/coty_2022.svg';

export default function Showroom() {
  const container = useRef();
  const leftRef = useRef();
  const rightRef = useRef();
  const context = useContext(KiaContext);

  const [ scenes, setScenes ] = useState([]);
  const [ scene, setScene ] = useState({});
  const [ showInfo, setShowInfo ] = useState(getItemTemp(STORAGE.showInfo) === null);
  const [ showElectric, setShowElectric ] = useState(false);
  const [ loading, setLoading ] = useState(true);

  let viewer;

  const stopPropagation = (el) => ['touchstart', 'touchmove', 'touchend', 'touchcancel'].forEach(event => el.addEventListener(event, e => e.stopPropagation()));

  const setupHotspots = (scene, data) => {
    if (CONFIG.DEBUG) { console.log('Add hotspots', scene, data); }

    data.linkHotspots.forEach(hotspot => {
      const el = document.querySelector(`.showroom__hotspot[data-scene="${data.id}"][data-target="${hotspot.target}"]`);
      if (!el) { return; }

      if (CONFIG.DEBUG) { console.log('Create scene hotspot', hotspot, el); }

      scene.hotspotContainer().createHotspot(el, { yaw: hotspot.yaw, pitch: hotspot.pitch });
      stopPropagation(el);
    });

    data.infoHotspots.forEach(hotspot => {
      const el = document.querySelector(`.showroom__hotspot[data-scene="${data.id}"][data-car="${ hotspot.car }"]`);
      if (!el) { return; }

      if (CONFIG.DEBUG) { console.log('Create car hotspot', hotspot, el); }

      scene.hotspotContainer().createHotspot(el, { yaw: hotspot.yaw, pitch: hotspot.pitch });
      stopPropagation(el);
    });
  };

  const setupCampaigns = (scene, data) => {
    if (!data.campaigns) { return; }
    data.campaigns.forEach(campaign => {
      const el = document.querySelector(`.showroom__campaign[data-scene="${data.id}"][data-campaign="${campaign.id}"]`);
      if (!el) { return; }

      if (CONFIG.DEBUG) { console.log('Create campaign', campaign, el); }

      scene.hotspotContainer().createHotspot(el, { yaw: campaign.yaw, pitch: campaign.pitch }, { perspective: { radius: campaign.radius, extraTransforms: `rotateX(${ campaign.rotation }deg)` }})
      stopPropagation(el);
    });
  };

  const startAutoRotate = (scene) => {
    if (CONFIG.DEBUG) { return; }
    const autorotate = Marzipano.autorotate({
      yawSpeed: 0.03,
      targetPitch: 0,
      targetFov: Math.PI/2
    });

    if (CONFIG.DEBUG) { console.log('START AUTOROTATE', scene.scene.viewer()); }

    // scene.scene.viewer().startMovement(autorotate);
    scene.scene.viewer().setIdleMovement(3000, autorotate);
  }

  const setupScenes = (rawScenes, viewer) => {
    const scenes = rawScenes.map(data => {
      const {
        id,
        levels,
        initialViewParameters,
        faceSize
      } = data;
      const urlPrefix = `${CONFIG.CDN_BASE}${CONFIG.SHOWROOM_PATH}`;

      const source = Marzipano.ImageUrlSource.fromString(
        `${urlPrefix}/tiles/${id}/{z}/{f}/{y}/{x}.jpg`,
        {
          cubeMapPreviewUrl: `${urlPrefix}/tiles/${id}/preview.jpg`,
        });
      const geometry = new Marzipano.CubeGeometry(levels);

      const mainLimiter = Marzipano.RectilinearView.limit.traditional(faceSize, 80*Math.PI/180, 80*Math.PI/180);
      // const mainLimiter = Marzipano.util.compose(
      //   Marzipano.RectilinearView.limit.vfov(60*Math.PI/180, 120*Math.PI/180),
      //   Marzipano.RectilinearView.limit.hfov(60*Math.PI/180, 120*Math.PI/180));
      const yLimiter = Marzipano.RectilinearView.limit.pitch(-CONFIG.PITCH_OFFSET, CONFIG.PITCH_OFFSET);

      const limiter = (params) => {
        let newParams = mainLimiter(params);
        newParams = yLimiter(newParams);

        return newParams;
      }

      const view = new Marzipano.RectilinearView(initialViewParameters, limiter);

      const scene = viewer.createScene({
        source,
        geometry,
        view,
        pinFirstLevel: true,
      });

      setupHotspots(scene, data);
      setupCampaigns(scene, data);

      return {
        data,
        scene,
        view
      };
    });

    setScenes(scenes);
  };

  const renderShowroom = () => {
    const { showroom } = context;

    const viewerOptions = {
      controls: {
        ...showroom.settings
      },
      stage: { progressive: true },
    };

    viewer = new Marzipano.Viewer(container.current, viewerOptions);

    var velocity = 0.3;
    var friction = 1;
    var controls = viewer.controls();
    const viewLeftElement = document.querySelector('#go-left');
    const viewRightElement = document.querySelector('#go-right');

    controls.registerMethod('leftElement',  new Marzipano.ElementPressControlMethod(viewLeftElement,   'x', -velocity, friction), true);
    controls.registerMethod('rightElement', new Marzipano.ElementPressControlMethod(viewRightElement,  'x',  velocity, friction), true);

    setupScenes(showroom.scenes, viewer);

    const dragControlMethod = viewer.controls().method('mouseViewDrag').instance;
    dragControlMethod.addEventListener('active', () => {
      if (CONFIG.DEBUG) { console.log('start showroom drag'); }
      if (showInfo) {
        setShowInfo(false);
      }
    });
    dragControlMethod.addEventListener('inactive', () => {
      if (CONFIG.DEBUG) { console.log('stop showroom drag'); }

      const view = viewer.view();
      setItemTemp(STORAGE.showroomLocation, {
        yaw: view.yaw(),
        pitch: view.pitch(),
      });
    });
  };

  const showScene = (target) => {
    const newScene = scenes.find(({ data: { id } }) => id === target);

    if (CONFIG.DEBUG) { console.log('show scene', newScene, scene); }

    const params = {
      ...newScene.data.initialViewParameters,
      pitch: scene.view.pitch(),
      yaw: scene.view.yaw(),
    }

    newScene.view.setParameters(params);
    newScene.scene.switchTo({
      transitionDuration: 1000
    });

    setScene(newScene);
  };

  const lookAtPoint = (point, transitionDuration = 1000, targetScene = scene) => {
    if (!point.hasOwnProperty('yaw') || !point.hasOwnProperty('pitch')) { return; }

    const { yaw, pitch } = point;

    var destinationViewParameters = {
      yaw,
      pitch,
    };

    var options = {
      transitionDuration,
    };

    if (CONFIG.DEBUG) { console.log('look at point', destinationViewParameters, options); }

    targetScene.lookTo(destinationViewParameters, options);

    setItemTemp(STORAGE.showroomLocation, destinationViewParameters);
  };

  const onCarClick = (dlModel) => {
    if (!dlModel) { return; }
    GTM.track({
      dlCategory: 'Discover',
      dlAction: 'Click',
      dlModel,
    });
  };

  const onElectricClick = (isElectric) => {
    if (CONFIG.DEBUG) { console.log('SHOW ELECTRIC', isElectric); }

    setShowElectric(isElectric);

    GTM.track({
      dlCategory: 'All models',
      dlAction: 'Toggle go electric',
      dlLabel: isElectric ? 'On' : 'Off',
    });

    // if (showElectric) {
    //   showScene(SCENES.electric);
    // } else {
    //   showScene(SCENES.default);
    // }
    // showScene('electric');
  };

  useEffect(() => {
    if (!scenes.length) { return; }

    if (CONFIG.DEBUG) { console.log('scenes updated', scenes); }

    const [ scene ] = scenes;
    scene.view.setParameters(scene.data.initialViewParameters);
    scene.scene.switchTo({
      transitionDuration: 200,
    }, () => {
      setLoading(false);
    });

    setScene(scene);

    try {

      let location = {};

      const query = querystring.decode(window.location.search);

      if (query.hotspot) {
        const hotspot = context.showroom.scenes[0].infoHotspots.find(({ slug }) => `${slug}` === query.hotspot);
        if (CONFIG.DEBUG) { console.log('selected hotspot', hotspot); }
        location = hotspot || {};
      } else if (query.campaign) {
        const campaign = context.showroom.scenes[0].campaigns.find(({ id }) => `${id}` === query.campaign)
        if (CONFIG.DEBUG) { console.log('selected campaign', campaign); }
        location = campaign || {};
      }

      if (!location.hasOwnProperty('pitch')) {
        location = getItemTemp(STORAGE.showroomLocation);
        if (CONFIG.DEBUG) { console.log('selected location from storage', location); }

        startAutoRotate(scene);
      }

      lookAtPoint(location, 0, scene.scene);
    } catch(e) {
      if (CONFIG.DEBUG) { console.warn('Something went wrong looking at a point'); }
    }
  }, [scenes]);

  useEffect(() => {
    if (!container.current) { return; }
    if (CONFIG.DEBUG) { console.log('showroom init', context.showroom); }
    renderShowroom();
  }, [container, leftRef, rightRef]);

  const { showPromo } = context;

  return (
    <Page
      chat
      fixedFooter
      fixedHeader
      footer
      lightFooter
      lightHeader
      dlLabel="showroom"
      name={`showroom${showElectric ? ' showroom--electric' : ''}`}
    >

      { loading ? (<Loading page />) : null }

      <div className="showroom__switch">
        { showPromo && <PromoOffer showClose={true} showLegal={true} showCTA={true} /> }
        <Eswitch active={false} onClick={onElectricClick} />
      </div>

      <div className="showroom__actions">
        <Button color="transparent" mode="naked" icon={<Icon icon="arrow-left-long" />} id="go-left" />
        <Button color="transparent" mode="naked" icon={<Icon icon="arrow-right-long" />} id="go-right" />
      </div>

      <div ref={container} className="showroom__pano"></div>

      <div className="showroom__campaigns">
        {
          context.showroom.scenes.map(scene => {
            if (scene.campaigns && scene.campaigns.length) {
              return scene.campaigns.map(campaign => (
                <div className="showroom__campaign" key={`campaign--${campaign.id}`} data-scene={`${scene.id}`} data-campaign={campaign.id} data-hotspot={campaign.hotspot}>
                  <Campaign {...campaign} />
                </div>
              ))
            }
            return null;
          })
        }
      </div>

      <div className="showroom__hotspots">
        {
          context.showroom.scenes.map(scene => {
            if (scene.linkHotspots) {
              return scene.linkHotspots.map(hotspot => {
                const target = context.showroom.scenes.find(scene => scene.id === hotspot.target);
                return (
                  <div className="showroom__hotspot" key={`hotspot--${hotspot.target}`} data-scene={`${scene.id}`} data-target={`${hotspot.target}`}>
                    <Hotspot onClick={() => showScene(hotspot.target)}>
                      <div className="hotspot__label">{ target.name }</div>
                    </Hotspot>
                  </div>
                );
              });
            }
            return null;
          })
        }
        {
          context.showroom.scenes.map(scene => {
            if (scene.infoHotspots) {
              return scene.infoHotspots.map(hotspot => {
                const car = context.cars.find(car => car.id === hotspot.car);

                return (
                  <div className={`showroom__hotspot${car.is_electric ? ' showroom__hotspot--electric' : ''}${ car.slug ? '' : ' showroom__hotspot--reserve' }`} key={car.id} data-scene={`${scene.id}`} data-car={car.id}>
                    <Hotspot pulse={!car.cta && !!car.slug} modifier={car.slug} onClick={() => onCarClick(car.slug)} to={ car.slug ? `${context.prefix}${ROUTES.cars}/${car.slug}` : null}>

                      { car.badge ? (
                        <div className={`hotspot__badge${ showElectric ? ' hotspot__badge--electric' : '' }`}>
                          <img src={badge} alt="Car of the Year 2022" />
                        </div>
                      ) : null }

                      <div className="hotspot__logo">
                        { car.is_electric && showElectric ? (
                          <div className="hotspot__engines">
                            { car.engine_types.filter(type => ENGINE.electric.indexOf(type) >= 0).map(type => (<Engine type={type} key={`engine-${type}-${car.id}`} />)) }
                          </div>
                        ) : null}
                        <p className="hotspot__carname">{ car.price_default_short_name }</p>
                        {/* <p className="hotspot__cardescription">{ car.short_description }</p> */}
                        <p className="hotspot__price">
                          <span><FormattedMessage id="from" />&nbsp;{ formatPrice(car.price_motor_show, CONFIG.PRICE_FORMAT, context.currentLocale.locale) }</span>
                        </p>
                      </div>

                      { car.cta ? (
                        <div className="hotspot__cta">
                          <Button size={ context.mobile ? 'xsmall' : 'small' } mode="outline" color="secondary" href={car.cta[context.currentLocale.locale]}><FormattedMessage id="hotspot.cta" values={{ car: car.price_default_short_name }} /></Button>
                        </div>
                      ) : null }
                    </Hotspot>
                  </div>
                );
              });
            }
            return null;
          })
        }
      </div>
    </Page>
  );
}
