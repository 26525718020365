import config from "../config"

export const setItem = (key, value) => {
  try {
    localStorage.setItem(key, JSON.stringify(value));
    return true;
  } catch (e) {
    if (config.DEBUG) { console.error('Storage set error', e); }
    return false;
  }
}

export const getItem = (key) => {
  try {
    const value = localStorage.getItem(key);

    return JSON.parse(value);
  } catch(e) {
    if (config.DEBUG) { console.error('Storage get error', e); }
    return null;
  }
}

export const removeItem = (key) => {
  try {
    localStorage.removeItem(key);
    return true;
  } catch(e) {
    if (config.DEBUG) { console.error('Storage delete error', e); }
    return false;
  }
}

export const setItemTemp = (key, value) => {
  try {
    sessionStorage.setItem(key, JSON.stringify(value));
    return true;
  } catch (e) {
    if (config.DEBUG) { console.error('Storage set temp error', e); }
    return false;
  }
}

export const getItemTemp = (key) => {
  try {
    const value = sessionStorage.getItem(key);

    return JSON.parse(value);
  } catch(e) {
    if (config.DEBUG) { console.error('Storage get temp error', e); }
    return null;
  }
}

export const removeItemTemp = (key) => {
  try {
    sessionStorage.removeItem(key);
    return true;
  } catch(e) {
    if (config.DEBUG) { console.error('Storage delete temp error', e); }
    return false;
  }
}
