import "./maintenance.scss";

import React, { useEffect } from "react";

import GTM from "../../../common/services/gtm.service";
import Info from "../../02_molecules/info/Info";
import HeaderSimple from "../../03_organisms/header/HeaderSimple";

export default function Maintenance({ country, data }) {
  const pathName = window?.location?.pathname;
  const language = pathName?.toLowerCase().substring(0, 3) === "/nl" ? 'nl' : pathName?.toLowerCase().substring(0, 3) === "/fr" ? 'fr' : null

  useEffect(() => {
    GTM.track({
      event: "dlVirtualPageviewEvent",
      dlLabel: "Maintenance",
    });
  }, []);

  // redirect the maintenance mode to another url
  useEffect(() => {
    if (country && country.id && country.id === "lu") {
      if (data.redirect["fr-lu"])
        window.location.replace(data.redirect["fr-lu"]);
    }
    if (country && country.id && country.id === "be") {
      if (language === 'nl') {
        if (data.redirect["nl-be"])
          window.location.replace(data.redirect["nl-be"]);
      } else if (language === 'fr') {
        if (data.redirect["fr-be"])
          window.location.replace(data.redirect["fr-be"]);
      } else {
        if (data.redirect["nl-be"] || data.redirect["fr-be"])
          window.location.replace("https://www.kia.com/be/");
      }
    }
  }, [data]);

  return (
    <div className="page page--maintenance">
      <HeaderSimple fixed />
      <div className="page__inner section">
        <Info visible hideBG>
          {country.id === "lu" ? (
            <div
              dangerouslySetInnerHTML={{
                __html:
                  data.body["fr-lu"] || "<p><strong>Maintenance</strong></p>",
              }}
            />
          ) : (
            <>
              { language === 'fr' ? null : <div
                dangerouslySetInnerHTML={{
                  __html:
                    data.body["nl-be"] || "<p><strong>Maintenance</strong></p>",
                }}
              />
              }

              { language === 'nl' ? null : <div
                className={`${!language ? 'mt--10' : ''}`}
                dangerouslySetInnerHTML={{
                  __html:
                    data.body["fr-be"] || "<p><strong>Maintenance</strong></p>",
                }}
              />
              }
            </>
          )}
        </Info>
      </div>
    </div>
  );
}
