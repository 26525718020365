import React from 'react';
import { NavLink } from 'react-router-dom';
import './button.scss';

export default function Button(props) {
  const {
    children,
    className = '',
    to,
    href,
    icon,
    round,
    color = 'primary', // primary | secondary | light | dark
    mode = 'default', // default | outline | naked
    size = 'normal', // small | normal | large
    iconAfter,
    underline,
    ...restProps
  } = props;

  const classes = ['button', `button--${color}`, `button--${mode}`, `button--${size}`, ...className.split(' ')].filter(c => c);

  if (round) {
    classes.push('button--round');
  }
  if (icon && iconAfter) {
    classes.push('button--icon-after');
  }
  if (underline) {
    classes.push('button--underline');
  }

  const renderLabel = () => (<span className="button__label">{ children }</span>);
  const renderIcon = () => (<span className="button__icon">{ icon }</span>);

  const renderBody = () => (
    <>
      { icon ? renderIcon() : null }
      { children ? renderLabel() : null }
    </>
  );

  if (to) {
    return (<NavLink to={to} className={classes.join(' ')} {...restProps}>{ renderBody() }</NavLink>);
  }

  if (href) {
    return (<a href={href} target="_blank" rel="noopener noreferrer" className={classes.join(' ')} {...restProps}>{ renderBody() }</a>)
  }

  return (<button className={classes.join(' ')} {...restProps}>{ renderBody() }</button>)
}
