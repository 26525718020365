import './powertrain.scss';

import React, { useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { hasPrice } from '../buttonBanner/ButtonBanner';

import { Link } from 'react-router-dom';
import CONFIG from '../../../common/config';
import ROUTES from '../../../common/constants/routes.constants';
import { KiaContext } from '../../../common/context/main.context';
import { formatPrice } from '../../../common/utils/price.utils';
import Button from '../../01_atoms/button/Button';
import Icon from '../../01_atoms/icons/Icon';
import { wltp } from '../../wltp';
import PriceBox from '../priceBox/PriceBox';
import PriceInfo from '../priceinfo/Priceinfo';
import PriceinfoLegal from '../priceinfoLegal/PriceinfoLegal';
import PriceinfoPrice from '../priceinfoPrice/PriceinfoPrice';
import InspireBadge from './inspire-logo.png';
import StyleBadge from './style-logo.png';

export default function Powertrain(props) {
  const {
    title,
    description,
    power,
    type,
    price,
    price_discount,
    price_bonus,
    price_net,
    price_motor_show,
    easymove,
    kia_private_lease,
    kia_lease,
    financial_renting,
    nedc,
    price_calculator,
    action_valid_to,
    promo_information,
    image,
    model = '',
    // car,
    fuel_consumption,
    price_trade_bonus,
    co_two_emissions,
    highlight,
    // inspire,
    badge,
  } = props;

  const { currentLocale, showMonthlyPrices, isB2c } = useContext(KiaContext);
  const currentCar = {
    price: price,
    price_trade_bonus,
    title: title,
    action_valid_to: action_valid_to,
    co_two_emissions: co_two_emissions,
    fuel_consumption: fuel_consumption,
    easymove,
    kia_private_lease,
    kia_lease,
    financial_renting,
    power
  }

  const intl = useIntl();

  const wltpValue = wltp(fuel_consumption, co_two_emissions, type);

  // @todo: EV3 WLTP message check
  const showWLTPMessage = model === 'ev3';

  return (
    <div className="powertrain__wrapper">
      <div className={`powertrain${ highlight ? ' powertrain--highlighted' : '' }`}>
        <PowertrainHeader {...{engineType: type, title, power}} />
        <div className="powertrain__content">
          <PowertrainImage {...{image, title}} highlight={badge} />
          {!image && <PowertrainPriceCalculator {...{price_calculator}} />}
          <PowertrainPrices {...{model, price_motor_show, currentLocale, showMonthlyPrices, promo_information, price_discount, price_bonus, price_net, isB2c, currentCar, type}} />
          <div className="powertrain__exhaust my--0 mb--6">
            { wltpValue ? (<p className="exhaust exhaust--wltp my--0">{wltpValue}</p>) : null }
            { nedc ? (<p className="exhaust exhaust--ndec my--0">{nedc}</p>) : null }
            { showWLTPMessage && (<p className="exhaust exhaust--wltp-message mt--2" dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'powertrain.wltpmessage' }) }} />) }
          </div>
          <div className="powertrain__devider" />
          <div className="powertrain__package p--2" dangerouslySetInnerHTML={{ __html: description }} />
          <div className="powertrain__devider mb--6" />
          {price_calculator && image && <Button size="small" mode="outline" color="light" href={price_calculator} className="button--bold"><FormattedMessage id="car.fiscalculator" /></Button>}
          <p className="mt--1 mb--3 p--3 fc--text-medium bold"><FormattedMessage id="detail.price.disclaimer.b2b" /></p>
        </div>
      </div>
    </div>
  );
}

const PowertrainHeader = ({engineType, title, power}) => {
  return (
    <div className="powertrain__header">
      <div className="powertrain__header-highlight">
        <p><FormattedMessage id={`powertrain.highlight`} /></p>
      </div>
      <div className="powertrain__model py--2 px--1">
        <p className="mt--0 mb--1 bold model__name ">{ title }</p>
        <div className="my--0 model__tag">
          <div className="model__tag__power">{ power }</div>
          <div className="uppercase model__tag__engine">
            <FormattedMessage id={`powertrain.${engineType}`} />&nbsp;
          </div>
        </div>
      </div>
    </div>
  );
}

const PowertrainImage = ({image, title, highlight}) => {
  if (!image) return null

  const badge = highlight === 'inspire' ? InspireBadge : StyleBadge;

  return (
    <div className="powertrain__image">
      { highlight && <div className="powertrain__image__highlight"><img src={badge} alt={<formattedmessage id="powertrain.highlight" />} /></div>}
      <img src={ image } alt={ title } />
    </div>
  );
}

const PowertrainPriceCalculator = ({price_calculator}) => {
  if (!price_calculator) return null
  return <Button size="small" mode="outline" color="light" href={price_calculator} icon={<Icon icon="link" />} iconAfter className="mt--auto"><FormattedMessage id="car.fiscalculator" /></Button>
}

const PowertrainPrices = ({model, price_motor_show, currentLocale, showMonthlyPrices, promo_information, price_discount, price_bonus, price_net, isB2c, currentCar, type}) => {
  if (isB2c) return <PowertrainPricesB2cPrices {...{model, price_motor_show, currentLocale, showMonthlyPrices, promo_information, price_discount, price_bonus, price_net, currentCar, type}} />

  //b2b
  return <PowertrainPricesB2bPrices {...{model, price_motor_show, currentLocale, showMonthlyPrices, promo_information, price_discount, price_bonus, price_net, currentCar, type}} />
}

const PowertrainPricesB2cPrices = ({model, price_motor_show, currentLocale, showMonthlyPrices, promo_information, price_discount, price_bonus, price_net, currentCar, type}) => {
  const intl = useIntl();
  const { country } = useContext(KiaContext);

  // @todo: EV3 EV promo message check
  const showPromo = price_bonus && !isNaN(price_bonus) && (country.id != 'be' || price_net < CONFIG.CAR_FLANDERS_EV_PROMO_CAP) && CONFIG.CAR_SHOW_FLANDERS_EV_PROMO;

  return <>
    <div className="powertrain__prices">
      <div className="powertrain__price">
        <div className="uppercase mt--0 mb--0 price__name">
          <FormattedMessage id="from" />
        </div>
        <p className="my--0 bold price__amount">{ formatPrice(price_motor_show, CONFIG.PRICE_FORMAT, currentLocale.locale) }</p>
        {
          showPromo ? (
            <p className="price__extra price__extra--highlight"><span dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'powertrain.promo'},{ amount: formatPrice(price_bonus, CONFIG.PRICE_FORMAT, currentLocale.locale) }) }}></span>&nbsp;<sup>(5)</sup></p>
          ) : (
            <p className="price__extra" />
          )
        }
      </div>
      {showMonthlyPrices && <PriceLine {...{car: currentCar, locale: currentLocale.locale, type: 'easymove', model, promo_information, price_discount, price_bonus, price_net, price_motor_show, isB2c: true}} />}
    </div>
    <KiaPrivateLeasePrice car={currentCar} promo_information={promo_information} price_discount={price_discount} price_bonus={price_bonus} price_net={price_net} price_motor_show={price_motor_show} model={model} />
  </>
}

const PowertrainPricesB2bPrices = ({model, price_motor_show, currentLocale, showMonthlyPrices, promo_information, price_discount, price_bonus, price_net, currentCar, type}) => {
  const hasFinancialRentingPrice = hasPrice(showMonthlyPrices, currentCar, currentLocale.locale, 'financial_renting')
  const hasKiaLeasePrice = hasPrice(showMonthlyPrices, currentCar, currentLocale.locale, 'kia_lease')

  let prices = 0
  prices += hasFinancialRentingPrice ? 1 : 0
  prices += hasKiaLeasePrice ? 1 : 0

  return <div className={`powertrain__prices ${prices > 1 ? 'powertrain__prices__multiple' : ''}`}>
    {/*<div className={`powertrain__price ${prices > 1 ? '' : 'mr--3'}`}>
      <div className="uppercase mt--0 mb--0 price__name">
        <FormattedMessage id="from" />&nbsp;
      </div>
      <p className="my--0 bold price__amount">{ formatPrice(price_motor_show, CONFIG.PRICE_FORMAT, currentLocale.locale) }</p>
      <p className="my--0 price__extra" />
    </div>*/}
    <PriceLines {...{showMonthlyPrices, model, promo_information, price_discount, price_bonus, price_net, price_motor_show, car: currentCar, locale: currentLocale.locale}} />
  </div>
}

const KiaPrivateLeasePrice = ({car, model, promo_information, price_discount, price_bonus, price_net, price_motor_show}) => {
  const intl = useIntl();
  const {prefix, currentLocale} = useContext(KiaContext);
  const hasKiaPrivateLeasePrice = hasPrice(true, car, currentLocale.locale, 'kia_private_lease');

  return <div className="powertrain__kiaprivatelease">
    {!hasKiaPrivateLeasePrice && <div className="powertrain__kiaprivatelease__noprice"><span dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'kiaprivatelease_noprice'})}} /> <Link to={`${prefix}${ROUTES.quote}?model=${model}`}><FormattedMessage id="kiaprivatelease_noprice_offer" /></Link></div>}
    {hasKiaPrivateLeasePrice && <div className="powertrain__kiaprivatelease__price"><span dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'kiaprivatelease_price'}).replace('{ price }', formatPrice(car?.['kia_private_lease']?.price, CONFIG.PRICE_FORMAT, currentLocale.locale))}} /><InfoButton {...{car, promo_information, price_discount, price_bonus, price_net, price_motor_show, model, isB2c: true, locale: currentLocale.locale, type: 'kia_private_lease'}} /></div>}
  </div>;
}

const PriceLines = ({model, promo_information, price_discount, price_bonus, price_net, price_motor_show, showMonthlyPrices, car, locale}) => {
  const {currentLocale} = useContext(KiaContext);
  if (!showMonthlyPrices) return null

  const hasFinancialRentingPrice = hasPrice(showMonthlyPrices, car, locale, 'financial_renting')
  const hasKiaLeasePrice = hasPrice(showMonthlyPrices, car, locale, 'kia_lease')

  let prices = 0
  prices += hasFinancialRentingPrice ? 1 : 0
  prices += hasKiaLeasePrice ? 1 : 0

  if (prices === 0) return null

  const powerTrainInfoButtonData = {...{car, promo_information, price_discount, price_bonus, price_net, price_motor_show, model, isB2c: false, locale}}

  if (prices === 1 && hasFinancialRentingPrice) return <PriceBox {...{car, currentLocale, showMonthlyPrices, type: 'financial_renting', show_tco: true, powerTrainInfoButtonData: {...powerTrainInfoButtonData, type: 'financial_renting'}}} />
  if (prices === 1 && hasKiaLeasePrice) return <PriceBox {...{car, currentLocale, showMonthlyPrices, type: 'kia_lease', show_tco: true, powerTrainInfoButtonData: {...powerTrainInfoButtonData, type: 'kia_lease'}}} />
  return <div className= 'powertrain__prices__multiple__pricelines'>
    <PriceBox {...{car, currentLocale, showMonthlyPrices, type: 'financial_renting', show_tco: true, powerTrainInfoButtonData: {...powerTrainInfoButtonData, type: 'financial_renting'}}} />
    <PriceBox {...{car, currentLocale, showMonthlyPrices, type: 'kia_lease', show_tco: true, powerTrainInfoButtonData: {...powerTrainInfoButtonData, type: 'kia_lease'}}} />
  </div>
}

const PriceLine = ({car, locale, type, model, promo_information, price_discount, price_bonus, price_net, price_motor_show, isB2c}) => {
  const intl = useIntl();
  const typeMonth = intl.formatMessage({id: `${type}.month`});

  return <div className={`powertrain__price powertrain__price__${type} mt--4 mb--4`}>
    <div className={`powertrain__price__${type}`}>
      <div className="powertrain__price__name mt--0 mb--0"><FormattedMessage id={type === 'easymove' ? 'powertrain.easymove' : `${type}.label`} />:&nbsp;
      </div>
      <div className="powertrain__price__label">
        <p className={`lowercase powertrain__price__label__${type} my--0`}>
          <FormattedMessage id={`from`} />&nbsp;
          {formatPrice(car?.[type]?.price, CONFIG.PRICE_FORMAT, locale) }&nbsp;/&nbsp;<span  className="mr--1" dangerouslySetInnerHTML={{__html: typeMonth}} />*
        </p>
      </div>
    </div>
    {type === 'easymove' && (
      <>
        <div style={{textAlign: 'center'}}>
          <FormattedMessage id={`easymove.amount_last_payment`} values={{ amount_last_payment: formatPrice(car.easymove.amount_last_payment, CONFIG.PRICE_FORMAT_LEGAL, locale)}} />
        </div>
        <div className="powertrain__price__info__easymove">
          <FormattedMessage id={`easymove.more_info`} />
          <InfoButton {...{car, promo_information, price_discount, price_bonus, price_net, price_motor_show, model, locale, legalMessageId: `priceinfo.legal.${type}`, isB2c, type}} button buttonLabel={<FormattedMessage id={`easymove.more_info_link`} />} />
        .</div>
      </>
    )}
  </div>
}

export const InfoButton = ({car, promo_information, price_discount, price_bonus, price_net, price_motor_show, model, isB2c, locale, type, ...restProps}) => {

  const engineIsElectrical = car?.[type]?.is_electrical || false
  const messageId = type === 'easymove' ? `priceinfo.legal.${type}` : `priceinfo.legal.${type}.${engineIsElectrical ? 'electric' : 'fuel'}`

  console.log('bonus here?',price_bonus)

  return <div className="powertrain__price__label__infobutton">
    <PriceInfo
      title={car.title}
      promo={promo_information}
      price={{
        catalog: car.price,
        discount: price_discount,
        bonus: price_bonus,
        netto: price_net,
        takeover: car.price_trade_bonus,
        salon: price_motor_show,
      }}
      model={model}
      subtitle={car.power}
      {...restProps}
      isB2c={isB2c}
    >
      <PriceinfoPrice {...{isB2c, car, locale, type}} />
      <PriceinfoLegal car={car} locale={locale} messageId={messageId} type={type} />
    </PriceInfo>
  </div>
}
