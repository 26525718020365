import './pricebox.scss';

import { FormattedMessage, useIntl } from 'react-intl';

import React from 'react';
import CONFIG from '../../../common/config';
import { formatPrice } from '../../../common/utils/price.utils';
import { InfoButton as PowerTrainInfoButton } from '../../02_molecules/powertrain/Powertrain';
import { InfoButton } from '../../03_organisms/carpreview/CarPreview';

export default function PriceBox(props) {
  const { car, currentLocale, showMonthlyPrices, type, infoButtonData, powerTrainInfoButtonData, show_tco} = props;

  const intl = useIntl()
  const legend = intl.formatMessage({id: `${type}.legend`});
  const typeMonth = intl.formatMessage({id: `${type}.monthexclvat`});

  const price = showMonthlyPrices ?
    formatPrice(car?.[type]?.price, CONFIG.PRICE_FORMAT, currentLocale.locale) :
    formatPrice(car.price_motor_show, CONFIG.PRICE_FORMAT, currentLocale.locale)
  const hasPrice = Number(price.replace(/\D+/g, '')) !== 0
  if (!hasPrice) return null

  const tco = type === 'kia_lease' ? Number(car?.kia_lease?.tco) || 0 : 0

  return (
    <div className={`pricebox ${powerTrainInfoButtonData ? 'mod-black mod-small' : ''}`}>
        <fieldset>
            <legend dangerouslySetInnerHTML={{__html: legend}} />
            <div className={`pricebox__content`}>
                {showMonthlyPrices && <p className={`pricebox__content__title`}><FormattedMessage id={`from`} /></p>}
                <p className={`pricebox__content__price`}>{price}</p>
                {showMonthlyPrices &&
                    <div className={`pricebox__content__vatinfo`}>
                        <span dangerouslySetInnerHTML={{__html: typeMonth}} />
                        {infoButtonData && <InfoButton {...infoButtonData} />}
                        {powerTrainInfoButtonData && <PowerTrainInfoButton {...powerTrainInfoButtonData} />}
                    </div>
                }
                {show_tco && type === 'kia_lease' && tco !== 0 && <p className={`pricebox__content__tco`}>TCO: {formatPrice(tco, CONFIG.PRICE_FORMAT_LEGAL, currentLocale.locale)}</p>}
                {show_tco && (type !== 'kia_lease' || (type === 'kia_lease' && tco === 0)) && <p className={`pricebox__content__tco`}>&nbsp;</p>}
            </div>
        </fieldset>
    </div>
  )
}
