import React from 'react';
import { FormattedMessage } from 'react-intl';
import Button from '../../01_atoms/button/Button';
import Icon from '../../01_atoms/icons/Icon';
import './backButton.scss';

export default function BackButton({ back, showTop }) {

  return (
    <>
      <div className={`car__details--back${ showTop ? ' blend' : ''}`}>
        <Button mode="naked" color="light" onClick={() => back()} icon={<Icon icon="arrow-left" />} className="button--back" size="small"><FormattedMessage id="back.showroom" /></Button>
      </div>
    </>
  );
}