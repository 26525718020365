export const createYoutubeEmbedLink = (url = '', locale) => {
  try {
    // eslint-disable-next-line
    const match = [...url.match(/(youtu\.be\/|youtube\.com\/(watch\?(.*&)?v=|(embed|v)\/))([^\?&"'>]+)/)];

    return {
      // todo: nocookie?
      link: `https://youtube.com/embed/${match[5]}?enablejsapi=1&modestbranding=1&rel=0&hl=${locale}&vq=hd1080`,
      id: match[5],
    }
  } catch(e) {
    console.error('Error parsing youtube link', url, e);
  }
  return;
};
