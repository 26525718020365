import * as React from 'react';
import Button from '../../01_atoms/button/Button';
import Close from '../../01_atoms/icons/close/Close';
import './overlay.scss';

export default function VideoOverlay(props) {
  const { url, onClose } = props;

  return (
    <div className="overlay__wrapper">
      <div className="overlay overlay--video">
        <div className="overlay__actions">
          <Button color="transparent" onClick={onClose} icon={<Close />} />
        </div>

        <div className="overlay__content">
          <iframe width="560" height="315" src={url} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
        </div>
      </div>
    </div>
  )
}
