import React from 'react';
import './header.scss';
import { Helmet } from 'react-helmet-async';
import Logo from '../../01_atoms/logo/Logo';

export default function HeaderSimple(props) {
  const { fixed } = props;

  return (
    <header className={`header${ fixed ? ' header--fixed' : ''}`}>
      <Helmet>
        <meta property="og:url" content={window.location.href} />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <div className="header__content section">
        <div className="header__logo">
          <Logo />
        </div>
      </div>
    </header>
  );
}
