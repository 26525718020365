import React, { useEffect, useState } from "react";

import CONFIG from "./common/config";
import Loading from "./ui/02_molecules/loading/Loading";
import Maintenance from "./ui/05_buckets/maintenance/Maintenance";
import Routes from "./common/routes/Routes";
import { getLocales } from "./common/services/locales.service";
import { getMaintenanceSettings } from "./common/services/settings.service";

function App({ country }) {
  const [loading, setLoading] = useState(true);
  const [languages, setLanguages] = useState([]);
  const [maintenance, setMaintenance] = useState(null);
  const [tempMaintenance, setTempMaintenance] = useState('null');

  const init = async () => {
    try {
      const locales = await getLocales(country);

      if (CONFIG.DEBUG) {
        console.log("locales", locales, "for country", country);
      }

      setLanguages(locales);
    } catch (e) {
      if (CONFIG.DEBUG) {
        console.error("Failed to get locales");
      }
    }
    setLoading(false);
  };
  
  const initMaintenance = async () => {
    const newMaintenanceData = await getMaintenanceSettings();
    const statuses = newMaintenanceData && newMaintenanceData.status;
    const status =
      statuses === null
        ? 0
        : country?.id === "lu"
        ? statuses["fr-lu"]
        : // If either nl-be or fr-be has status "1" return "1"
        statuses["nl-be"] === 1
        ? statuses["nl-be"]
        : statuses["fr-be"];
    const newMaintenance = status ? newMaintenanceData : null;
    setTempMaintenance(JSON.stringify(newMaintenance));
  }
  
  useEffect(() => {
    if (JSON.stringify(maintenance) !== tempMaintenance) {
      setMaintenance(JSON.parse(tempMaintenance));
    }
  }, [maintenance, tempMaintenance]);

  useEffect(() => {
    initMaintenance()
    init();
    const interval = setInterval(() => {
      initMaintenance();
    }, 60 * 1000);
  
    return () => {
      clearInterval(interval);
    };
  }, []);

  if (loading) {
    return <Loading page />;
  }
  if (maintenance !== null) {
    return <Maintenance country={country} data={maintenance} />;
  }

  return <Routes locales={languages} country={country} />;
}

export default App;
