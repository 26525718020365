import './priceinfolegal.scss';

import React, { useContext } from 'react';

import { FormattedMessage } from 'react-intl';
import CONFIG from '../../../common/config';
import { KiaContext } from '../../../common/context/main.context';
import { formatPrice } from '../../../common/utils/price.utils';
import { wltp } from '../../wltp';

export default function PriceinfoLegal({car, locale, messageId, type}) {
    const {isB2c} = useContext(KiaContext)
    return isB2c ? <PriceinfoB2c {...{car, locale, messageId, type}} /> : <PriceinfoB2b {...{car, locale, messageId, type}} />
}

const PriceinfoB2c = ({car, locale, messageId, type}) => {
    const newMessageId = type === 'easymove' ? (car.price_trade_bonus ? "priceinfo.legal.easymove.trade_bonus" : "priceinfo.legal.easymove") : messageId
    return <PriceInfoMessage {...{car, locale, messageId: newMessageId, type}} />
}

const PriceinfoB2b = ({car, locale, messageId, type}) => {
    if (messageId && type) {
        return <PriceInfoMessage {...{car, locale, messageId, type}} />
    }

    // messageId and type are required for B2B
    return null
}

const getDecimalFromString = (str) => {
    if (!str) return null

    const string = `${str}`

    if (string.indexOf(',')) { // the string contains a , we will use this as decimal separator
        return parseFloat(string.match(/[\d,]+/g).join('').replace(',', '.'))
    }

    if (string.indexOf('.')) { // the string contains a . we will ignore it and use it as thousands separator
        return parseFloat(string.match(/[\d\.]+/g).join('')) //eslint-disable-line no-useless-escape
    }

    return parseFloat(str.match(/[\d]+/g))
}

const formatNumber = (number, decimals = 2, decimalSeparator = ',', thousandsSeparator = '.') => {
    if (!number) return null
    const float =  getDecimalFromString(number)
    if (!float) return null

    const parts = float.toString().split('.')
    const result = parts[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1' + thousandsSeparator)
    if (!decimals) return result

    if (parts.length === 1) return `${result}${decimalSeparator}${Array(decimals + 1).join('0')}` // there are no decimals in the number

    return `${result}${decimalSeparator}${Array(decimals + 1).fill('0').map((i, index) => parts[1][index] || i).join('')}`
}

const formatKm = (km, locale) => {
    // xx.xxx
    return formatNumber(km, 0, '', '.')
}

const formatCo2 = (co2, locale) => {
    // xxx
    return formatNumber(co2, 0, '', '')
}

const formatConsumption = (consumption, locale) => {
    // xx,x
    return formatNumber(consumption, 1, ',', '')
}

const formatDuration = (duration, locale) => {
    // xxx
    return formatNumber(duration, 0, '', '')
}

const PriceInfoMessage = ({car, locale, messageId, type}) => {
    const co_two_emissions = car.co_two_emissions || null
    const fuel_consumption = car.fuel_consumption || null
    let values = {
        car: car.title,
        power: car.power,
        catalog: <span>{ formatPrice(car.price, CONFIG.PRICE_FORMAT_LEGAL, locale) }</span>,
        action_valid_to: car.action_valid_to,
        co_two_emissions,
        fuel_consumption,
        wltp: wltp(fuel_consumption, co_two_emissions, car.engine_type),
    }

    if (type === 'easymove') {
        values = {
            ...values,
            amount_to_finance: <span>{ formatPrice(car.easymove.amount_to_finance, CONFIG.PRICE_FORMAT_LEGAL, locale) }</span>,
            amount_deposit: <span>{ formatPrice(car.easymove.amount_deposit, CONFIG.PRICE_FORMAT_LEGAL, locale) }</span>,
            amount_cash: <span>{ formatPrice(car.easymove.amount_cash, CONFIG.PRICE_FORMAT_LEGAL, locale) }</span>,
            amount_jkp: <span>{ car.easymove.amount_jkp }</span>,
            intrest: <span>{ car.easymove.amount_intrest }</span>,
            totalterms: car.easymove.amount_term + 1,
            terms: car.easymove.amount_term,
            amount_term: <span>{ formatPrice(car.easymove.price, CONFIG.PRICE_FORMAT_LEGAL, locale) }</span>,
            amount_last_payment: <span>{ formatPrice(car.easymove.amount_last_payment, CONFIG.PRICE_FORMAT_LEGAL, locale) }</span>,
            amount_total: <span>{ formatPrice(car.easymove.amount_total, CONFIG.PRICE_FORMAT_LEGAL, locale) }</span>
        }
    } else if(type === 'kia_private_lease') {
        values = {
            ...values,
            price: <span>{ formatPrice(car?.kia_private_lease?.price, CONFIG.PRICE_FORMAT_LEGAL, locale) }</span>,
            co_two_emissions: formatCo2((car?.kia_private_lease?.co_two_emissions || null), locale),
            duration: formatDuration((car?.kia_private_lease?.duration || null), locale),
            fuel_consumption: formatConsumption((car?.kia_private_lease?.fuel_consumption || null), locale),
            km: formatKm((car?.kia_private_lease?.km || null), locale),
            start_date: car?.kia_private_lease?.start_date || null
        }
    } else if (type === 'kia_lease') {
        values = {
            ...values,
            price: <span>{ formatPrice(car?.kia_lease?.price, CONFIG.PRICE_FORMAT_LEGAL, locale) }</span>,
            co_two_emissions: car?.kia_lease?.co_two_emissions || null,
            duration: car?.kia_lease?.duration || null,
            fuel_consumption: car?.kia_lease?.fuel_consumption || null,
            ind_fisc_value: <span>{ formatPrice(car?.kia_lease?.ind_fisc_value, CONFIG.PRICE_FORMAT_LEGAL, locale) }</span>,
            km: car?.kia_lease?.km || null,
            start_date: car?.kia_lease?.start_date || null,
            tco: formatPrice(car?.kia_lease?.tco || null, CONFIG.PRICE_FORMAT_LEGAL, locale)
        }
    } else if (type === 'financial_renting') {
        values = {
            ...values,
            price: <span>{ formatPrice(car?.financial_renting?.price, CONFIG.PRICE_FORMAT_LEGAL, locale) }</span>,
            co_two_emissions: car?.financial_renting?.co_two_emissions || null,
            down_payment: <span>{ formatPrice(car?.financial_renting?.down_payment || null, CONFIG.PRICE_FORMAT_LEGAL, locale) }</span>,
            duration: car?.financial_renting?.duration || null,
            fuel_consumption: car?.financial_renting?.fuel_consumption || null,
            invoiced_amount: <span>{ formatPrice(car?.financial_renting?.invoiced_amount || null, CONFIG.PRICE_FORMAT_LEGAL, locale) }</span>,
            purchase_option_percentage: car?.financial_renting?.purchase_option_percentage || null,
            valid_date: car?.financial_renting?.valid_date || null
        }
    }

    return <p className="mb--2 priceinfolegal"><FormattedMessage id={messageId} values={values} /></p>
}
