import React, { useContext, useRef } from 'react';
import Slider from "react-slick";
import YouTube from 'react-youtube';
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { KiaContext } from '../../../common/context/main.context';
import Button from '../../01_atoms/button/Button';
import Icon from '../../01_atoms/icons/Icon';
import './carousel.scss';

export default function CarouselSlick(props) {
  const { items, slickSettings = {}, onChange = () => {}, onVideoPlay = () => {}, onVideoEnd = () => {} } = props;
  const { language } = useContext(KiaContext);

  const slider = useRef();

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // appendDots: dots => <ul>{dots}</ul>,
    // customPaging: function(i){
    //   return(
    //   <div className="slider__rectangle" onClick={() => console.log('index', i) && slider.current.slickGoTo(i)}>
    //   </div>
    //   );
    // },
    dotsClass: "slick-dots slick-thumb",
    // adaptiveHeight: true,
    ...slickSettings
  };

  const onBeforeChange = () => {
    // refresh iframe
    const item = document.querySelector('.slick-active .carousel__item iframe');

    if (item) {
      // stop playing youtube video
      item.contentWindow.postMessage(`{"event":"command","func":"stopVideo","args":""}`, '*');
    }

  };

  const onPrev = () => {
    slider.current.slickPrev();
  };

  const onNext = () => {
    slider.current.slickNext();
  };

  const renderImage = (item) => (
    <picture className="carousel__item__visual">
      <source srcSet={item.model_type} media="(min-width: 768px)" />
      <img src={ item.media } alt={item.description} />
    </picture>
  );


  const renderVideo = (item) => {
    if (item.media) {
      return (
        <a href={`${item.link}&autoplay=1`} target="_blank" rel="noopener noreferrer" title="Video" className="carousel__item__link">
          <picture className="carousel__item__visual">
            <source srcSet={item.model_type} media="(min-width: 768px)" />
            <img src={ item.media } alt={item.description} />
          </picture>
          <Icon icon="video" />
        </a>
      );
    }

    return (
      <div className="carousel__item__video">
        {
          // @todo: switch to nocookie
        }
        <YouTube
          id={`video-${item.id}`}
          opts={{
            width: 720,
            height: 540,
            playerVars: {
              enablejsapi: 1,
              modestbranding: 1,
              rel: 0,
              hl: language.locale,
              playsinline: 1,
            }
          }}
          videoId={item.videoId}
          onPlay={onVideoPlay}
          onEnd={onVideoEnd}
        />
      </div>
    );
  };

  return (
    <div className="carousel__wrapper">
      <Slider
        {...settings}
        beforeChange={onBeforeChange}
        afterChange={onChange}
        className="carousel"
        ref={slider}
      >
        { items.map((item, key) => (
            <div className="carousel__item" key={`citem--${key}`} data-index={key}>
              { item.type === 'image' ? renderImage(item) : null }
              { item.type === 'youtube' ? renderVideo(item) : null }
            </div>
        )) }
      </Slider>

      { items.length > 1 ? (
        <div className="carousel__actions">
          <Button mode="naked" icon={<Icon icon="arrow-left-long" />} className="carousel__action carousel__action--left fc--text-light" onClick={onPrev} />
          <Button mode="naked" icon={<Icon icon="arrow-right-long" />} className="carousel__action carousel__action--right fc--text-light" onClick={onNext} />
        </div>
      ) : null }
    </div>
  );
}
