import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import GTM from '../../../common/services/gtm.service';
import Button from '../../01_atoms/button/Button';
import Info from '../../02_molecules/info/Info';
import HeaderSimple from '../../03_organisms/header/HeaderSimple';
import './notfound.scss';

export default function NotFound() {
  const params = useParams();

  useEffect(() => {
    GTM.track({
      event: 'dlVirtualPageviewEvent',
      dlLabel: 'Not found',
    });
  }, []);

  return (
    <div className="page page--notfound">
      <HeaderSimple fixed />
      <div className="page__inner section">
        <Info visible>
          <h1 className="heading--1 mt--0 mb--4"><FormattedMessage id="notfound.title" defaultMessage="Woops" /></h1>
          <p className="p--1 mt--0 mb--4"><FormattedMessage id="notfound.content" defaultMessage="This is not the page you are looking for." /></p>
          <Button to={`/${params.lang || ''}`}><FormattedMessage id="notfound.gohome" defaultMessage="Go back home" /></Button>
        </Info>
      </div>
    </div>
  );
}
