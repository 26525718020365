import React, { useContext, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import CONFIG from '../../../common/config';
import CONFIGURATOR from '../../../common/constants/configurator.constants';
import { KiaContext } from '../../../common/context/main.context';
import { sendFromConfigurator } from '../../../common/services/configurator.service';
import './carconfigurator.scss';

export default function CarConfigurator() {
  const { hash, slug } = useParams();
  const { currentLocale, cars, mobile } = useContext(KiaContext);

  const car = cars.find(car => car.slug === slug);
  document.documentElement.style.setProperty('--configurator--bg', `url(${encodeURI(car.image)})`);

  const onLoaded = async () => {
    sendFromConfigurator({
      status: 'loading',
      message: 'SCRIPT_LOADED',
    });

    window.ndexp.widget.load().then(() => {
      const { api } = window.ndexp;
      api.controls.hide();

      sendFromConfigurator({
        status: 'loading',
        message: 'CONFIGURATOR_LOADED',
      });

      const materials = api.paint.getColorsMaterials();

      const animations = api.interactions.getAnimations();

      const defaultColor = api.paint.getDefaultPaint();

      const viewpoints = [...api.camera.getExteriorViewPoints(), ...api.camera.getInteriorViewPoints()];

      const canvas = document.querySelector(('#my_canvas_container canvas'));

      if (mobile && canvas) {
        // fake scroll down
        const evt = document.createEvent('MouseEvents');
        evt.initEvent('wheel', true, true);
        evt.deltaY = +1000;

        canvas.dispatchEvent(evt);
      }

      sendFromConfigurator({
        status: 'ready',
        message: 'DATA_LOADED',
        data: {
          materials,
          animations,
          defaultColor,
          viewpoints,
        }
      });

    });

  };

  const onFocus = () => {
    try {
      const { api } = window.ndexp;
      api.controls.hide();
    } catch(e) {
      if (CONFIG.DEBUG) { console.log('Configurator not loaded on focus'); }
    }
  }

  const onMessage = (e) => {
    if (!(e.origin.indexOf(currentLocale.domain) >= 0 || e.origin.indexOf(currentLocale.configurator) >= 0)) { return; }

    try {
      if (CONFIG.DEBUG) { console.log('Configurator received data', e.data); }

      if (e.data.source === CONFIGURATOR.source && e.data.direction === CONFIGURATOR.message_to) {
        const { payload = {} } = e.data;
        const { api } = window.ndexp;

        if (CONFIG.DEBUG) { console.log('received inside configurator', payload); }

        if (payload.action === CONFIGURATOR.hideControls) {
          api.controls.hide();
        }

        if (payload.action === CONFIGURATOR.toggleAnimation) {
          api.interactions.toggleAnimationByName(payload.data);
        }

        if (payload.action === CONFIGURATOR.setColor) {
          api.paint.setColorByName(payload.data);
        }

        if (payload.action === CONFIGURATOR.seeInterior) {
          api.camera.setViewPointByName(CONFIG.DEFAULT_CAR_VIEW_INTERIOR);
        }

        if (payload.action === CONFIGURATOR.seeExterior) {
          api.camera.setViewPointByName(CONFIG.DEFAULT_CAR_VIEW_EXTERIOR);
        }

        if (payload.action === CONFIGURATOR.toggleLights) {
          api.interactions.toggleLights()
        }

        if (payload.action === CONFIGURATOR.seeFrontseats) {
          api.camera.setViewPointByName(CONFIGURATOR.animations.frontseats);
        }

        if (payload.action === CONFIGURATOR.seeBackseats) {
          api.camera.setViewPointByName(CONFIGURATOR.animations.backseats);
        }
      }
    } catch (e) {
      if (CONFIG.DEBUG) {
        console.warn(e);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('message', onMessage, false);
    window.addEventListener('focus', onFocus, false);

    if (CONFIG.DEBUG) { console.log('CONFIGURATOR LOAD'); }

    sendFromConfigurator({
      status: 'loading',
      message: 'IFRAME_LOADED',
    });

    const src = window.ndev[0] + window.ndev[1] + 'experience.js';
    const s = 'script';
    const script = document.createElement(s);
    script.type = 'text/javascript';
    script.src = src;

    script.onload = onLoaded;

    document.body.appendChild(script);

    return () => {
      window.removeEventListener('message', onMessage);
      window.removeEventListener('focus', onFocus);
    }
  }, []);

  return (
    <div className="carconfigurator">
      <div className="carconfigurator__preload">
        <p className="heading--4 center my--0"><FormattedMessage id="configurator.loading" /></p>
      </div>
      <div className="nd-experience-visualiser" data-hash={hash} data-locale={currentLocale.clocale} data-lazy-load="0" data-mobile-lazy-load="0" />
    </div>
  );
}
