import React from 'react';
import './banner.scss';

export default function Banner(props) {
  const { name, children, actions } = props;

  return (
    <aside className={`banner banner--${name}`}>
      <div className="banner__content section section--6">
        <div className="banner__meta">
          { children }
        </div>
        <div className="banner__actions">
          { actions }
        </div>
      </div>
    </aside>
  );
}
