import './buttonBanner.scss';

import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import CONFIG from '../../../common/config';
import CONTACT from '../../../common/constants/contact.constants';
import ROUTES from '../../../common/constants/routes.constants';
import { KiaContext } from '../../../common/context/main.context';
import GTM from '../../../common/services/gtm.service';
import { formatPrice } from '../../../common/utils/price.utils';
import Button from '../../01_atoms/button/Button';
import Icon from '../../01_atoms/icons/Icon';
import Box from '../../02_molecules/box/Box';
import PriceInfo from '../priceinfo/Priceinfo';
import PriceinfoLegal from '../priceinfoLegal/PriceinfoLegal';
import PriceinfoPrice from '../priceinfoPrice/PriceinfoPrice';
import { PromoOffer } from '../promo/PromoOffer';

function ButtonBanner({ car, children, showMore, movePrice }, ref) {
  const {
    prefix,
    mobile,
    currentLocale,
    // dealer,
    showMonthlyPrices,
    isB2c,
    showPromo,
  } = useContext(KiaContext);

  // @todo: remove when ev3 testdrive is available
  const showTestdrive = true; // car.slug !== 'ev3';
  const showOffer = true;
  const showTradin = isB2c;

  const onContactClick = (dlLabel) => {
    GTM.track({
      dlCategory: 'CTA',
      dlAction: 'Click',
      dlLabel,
    });
  };

  // const scrollToPowertrains = () => {
  //   const $powertrains = document.querySelector('#powertrains');

  //   $powertrains.scrollIntoView({
  //     behavior: 'smooth',
  //     block: 'center',
  //   });
  // };

  const [hidePrice, setHidePrice] = useState(movePrice);
  useEffect(() => {
    setHidePrice(movePrice);
  }, [movePrice]);

  return (showTestdrive || showOffer || showTradin) && (
    <>
      <div className="car__button__details" ref={ref}>
        <Box className="car__buttons">
          <div className={`promo__wrapper${isB2c ? ' show-border' : ''}`}>
          { showPromo && <PromoOffer showLegal={true} border={false} /> }
          <div className="car__buttons__wrapper">
            { hidePrice || mobile ? (
              <>
              { !showMore || mobile ? (
              <div className="car__buttons__price__wrapper">
                { mobile ? <p className="car__buttons__carname fc--text-light bold ">{ car.price_default_short_name }</p> : null}
                <ButtonBannerPrice {...{car, isB2c, showMonthlyPrices, locale: currentLocale.locale}} />
              </div>
              ) : null }
            </>
            ) : null }
            <div className="car__buttons__actions">
              { showTestdrive && (<Button size={mobile ? 'xsmall' : 'small'} onClick={() => onContactClick(CONTACT.testdrive)} to={`${prefix}${ROUTES.testdrive}?model=${car.slug}`} color="secondary" mode="outline" className="car__button__action" icon={!mobile ? <Icon icon="key"/> : null}><FormattedMessage id="detail.testdrive" /></Button>)}

              { showOffer && (<Button size={mobile ? 'xsmall' : 'small'} onClick={() => onContactClick(CONTACT.quote)} to={`${prefix}${ROUTES.quote}?model=${car.slug}`} color="secondary" mode="outline" className="car__button__action" icon={!mobile ? <Icon icon="offer"/> : null}><FormattedMessage id="detail.quote" /></Button>)}
              { showTradin && <Button size={mobile ? 'xsmall' : 'small'} onClick={() => onContactClick(CONTACT.tradein)} to={`${prefix}${ROUTES.tradein}?model=${car.slug}`} color="secondary" mode="outline" className="car__button__action" icon={!mobile ? <Icon icon="trade"/> : null}><FormattedMessage id="detail.tradein" /></Button>}
            </div>
          </div>
          </div>
        </Box>

        { children }
      </div>
    </>
  );
}

const ButtonBannerPrice = ({car, locale, showMonthlyPrices, isB2c}) => {
  if (isB2c)
    return <PriceLine {...{car, locale, showMonthlyPrices, isB2c, type: 'easymove'}} />

  // b2b
  const hasFinancialRentingPrice = hasPrice(showMonthlyPrices, car, locale, 'financial_renting')
  const hasKiaLeasePrice = hasPrice(showMonthlyPrices, car, locale, 'kia_lease')
  let prices = 0
  prices += hasFinancialRentingPrice ? 1 : 0
  prices += hasKiaLeasePrice ? 1 : 0

  if (prices === 0) return null
  if (prices === 1 && hasFinancialRentingPrice) return <PriceLine {...{car, locale, showMonthlyPrices, isB2c, type: 'financial_renting'}} />
  if (prices === 1 && hasKiaLeasePrice) return <PriceLine {...{car, locale, showMonthlyPrices, isB2c, type: 'kia_lease'}} />

  return <div className="car__buttons__prices">
    <PriceLine {...{car, locale, showMonthlyPrices, isB2c, type: 'financial_renting'}} />
    <PriceLine {...{car, locale, showMonthlyPrices, isB2c, type: 'kia_lease'}} />
  </div>
}

export const getPrice = (showMonthlyPrices, car, locale, type) => {
  return showMonthlyPrices ?
    formatPrice(car?.[type]?.price, CONFIG.PRICE_FORMAT, locale) :
    formatPrice(car.price_motor_show, CONFIG.PRICE_FORMAT, locale)
}

export const hasPrice = (showMonthlyPrices, car, locale, type) => {
  const price = getPrice(showMonthlyPrices, car, locale, type)
  return Number(price.replace(/\D+/g, '')) !== 0
}

const PriceLine = ({car, locale, showMonthlyPrices, isB2c, type}) => {
  const intl = useIntl()
  const typeMonth = intl.formatMessage({id: `${type}.month`});

  if (!hasPrice(showMonthlyPrices, car, locale, type)) return null
  const price = getPrice(showMonthlyPrices, car, locale, type)

  return <div className="car__buttons__price">
  <div className="mt--0 mb--0 car__buttons__price__name mr--2"><FormattedMessage id={`${type}.label`} />&nbsp;</div>
  <div className="mt--0 mb--0">
    { showMonthlyPrices ?
      (<p className="my--0 car__buttons__price__amount car__buttons__price__amount--monthly">
        <FormattedMessage id={`from`} />&nbsp;
        {price} <small className="p--2 mr--2">&nbsp;/&nbsp;<span dangerouslySetInnerHTML={{__html: typeMonth}} /></small></p>) :
      (<p className="my--0 bold car__buttons__price__amount">{price}</p>)
    }
  </div>
  <InfoButton  {...{car, isB2c, locale, type}}/>
</div>
}

const InfoButton = ({car, isB2c, locale, type}) => {

  const engineIsElectrical = car?.[type]?.is_electrical || false
  const messageId = type === 'easymove' ? `priceinfo.legal.${type}` : `priceinfo.legal.${type}.${engineIsElectrical ? 'electric' : 'fuel'}`

  return <div className="car__buttons__price__infobutton">
  <PriceInfo
    title={car.title}
    subtitle={car.power}
    promo={car.price_default_promo_information}
    price={{
      catalog: car.price,
      discount: car.price_discount,
      bonus: car.price_bonus,
      netto: car.price_net,
      takeover: car.price_trade_bonus,
      salon: car.price_motor_show,
    }}
    model={car.slug}
  >
    <PriceinfoPrice {...{isB2c, car, locale, type}} />
    <PriceinfoLegal {...{car, type, locale, messageId}} />
  </PriceInfo>
</div>
}

export default React.forwardRef(ButtonBanner);
