const CONFIG = {
  DEBUG: process.env.NODE_ENV === "development",
  VERSION: "4.25",
  API_BASE: process.env.REACT_APP_API_BASE,
  OTHER_API_BASE: process.env.REACT_APP_OTHER_API_BASE,
  CDN_BASE: process.env.REACT_APP_CDN_BASE,
  API_TOKEN: process.env.REACT_APP_API_TOKEN,
  IS_B2C: process.env.REACT_APP_PLATFORM_TYPE,

  COUNTRIES: [
    {
      id: "be",
      country: "Belgium",
      domain: process.env.REACT_APP_DOMAIN_BE,
      configurator: process.env.REACT_APP_CONFIGURATOR_BE,
      other_platform_url: process.env.REACT_APP_OTHER_PLATFORM_URL_BE,
    },
    {
      id: "lu",
      country: "Luxembourg",
      domain: process.env.REACT_APP_DOMAIN_LU,
      configurator: process.env.REACT_APP_CONFIGURATOR_LU,
      other_platform_url: process.env.REACT_APP_OTHER_PLATFORM_URL_LU,
    },
  ],

  SUPPORTED_LANGS: [
    {
      locale: "nl-be",
      label: "Nederlands",
      short: "NL",
      slug: "nl",
      country: "be",
      cookieID: process.env.REACT_APP_COOKIE_ID_BENL,
      testdrive: process.env.REACT_APP_TESTDRIVE_URL_BENL,
    },
    {
      locale: "fr-be",
      label: "Français",
      short: "FR",
      slug: "fr",
      country: "be",
      cookieID: process.env.REACT_APP_COOKIE_ID_BEFR,
      testdrive: process.env.REACT_APP_TESTDRIVE_URL_BEFR,
    },
    {
      locale: "fr-lu",
      label: "Français",
      short: "FR",
      slug: "fr",
      country: "lu",
      cookieID: process.env.REACT_APP_COOKIE_ID_LUFR,
      testdrive: process.env.REACT_APP_TESTDRIVE_URL_LUFR,
    },
  ],

  MOBILE_WIDTH: 1024,

  DEFAULT_CAR_VIEW_EXTERIOR: "Default",
  DEFAULT_CAR_VIEW_INTERIOR: "Drivers Seat",

  DEFAULT_CONTACT_SOURCE: "offer",

  PITCH_OFFSET: 0.05,

  PRICE_FORMAT: {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  },
  PRICE_FORMAT_LEGAL: {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  },

  CAMPAIGN_RADIUS: 4000,

  // SHOWROOM_PATH: "/showroom-bms-2023",
  SHOWROOM_PATH: '/showroom-20240711',

  WOOSMAP_KEY: process.env.REACT_APP_WOOSMAP_KEY,
  MAX_DEALERS: 10,

  // CAR_TAEG: '1.49%', // jkp
  // CAR_INTREST: '1.49%', // intrest
  // CAR_TERMS: 47,

  // CAR_TAEG_ALT: '0.99%', // jkp for sportage
  // CAR_INTREST_ALT: '0.99%', // intrest for sportage

  CAR_FLANDERS_EV_PROMO_CAP: 40000,
  CAR_SHOW_FLANDERS_EV_PROMO: true,
};

export default CONFIG;
