import axios from 'axios';
import CONFIG from '../config';

export const getLocales = (country) => axios.get(`${CONFIG.API_BASE}/locales?v=${CONFIG.VERSION}`)
  .then(res => res.data)
  .then(res => {
    return Object.keys(res.data).map(key => {
      const payload = {...res.data[key]};

      if (!payload.enabled) { return null; }
      if (payload.country_iso !== country.id ) { return null; }

      const lang = CONFIG.SUPPORTED_LANGS.find(lang => lang.locale === key);

      if (!lang) { return null; }

      payload.slug = lang.slug;
      payload.short = lang.short;
      payload.locale = key;
      payload.cookieID = lang.cookieID;
      payload.testdrive = lang.testdrive;

      payload.domain = country.domain;
      payload.configurator = country.configurator;

      if (key === 'nl-be') {
        payload.clocale = 'nl_BE';
      } else {
        payload.clocale = 'fr';
      }

      return payload;
    }).filter(c => c);
  });
