const CONFIGURATOR = {
  source: 'CONFIGURATOR_SERVICE',
  message_from: 'OUT',
  message_to: 'IN',

  hideControls: 'HIDE_CONTROLS',

  toggleAnimation: 'TOGGLE_ANIMATION',
  setColor: 'SET_COLOR',
  toggleLights: 'TOGGLE_LIGHTS',

  seeInterior: 'SET_INTERIOR',
  seeExterior: 'SET_EXTERIOR',

  seeFrontseats: 'SET_FRONTSEATS',
  seeBackseats: 'SET_BACKSEATS',

  animations: {
    frontdoors: 'Front Doors',
    backdoors: 'Rear Doors',
    trunk: 'Boot',
    lights: 'Lights',
    frontseats: 'Drivers Seat',
    backseats: 'Rear Seat',
  }
};

export default CONFIGURATOR;
