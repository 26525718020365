export const wltp = (fuel_consumption = null, co_two_emissions = null, engine = null) => {
    let wltp = ''
    if (fuel_consumption !== null && fuel_consumption !== '') {
        const unit = engine === 'ev' ? 'kWh' : 'l'
        wltp+= `${fuel_consumption} ${unit}/100 km (WLTP)(4)`
    }
    if (co_two_emissions !== null && co_two_emissions !== '') {
        if (wltp !== '') wltp+= ' '
        wltp+= `${co_two_emissions} g CO2/km (WLTP)(4)`
    }
    return wltp
}