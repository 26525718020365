import './switch.scss'

import React, { useState } from 'react'

export default function Switch({active, leftLabel, rightLabel, onClick}) {
  const [ isActive, setActive ] = useState(active)
  const activate = () => {
    onClick(true)
    setActive(true)
  }
  
  const deActivate = () => {
    onClick(false)
    setActive(false)
  }

  const toggle = () => {
    isActive ? deActivate() : activate()
  }

  return (
    <div className={`switch${ isActive ? ' switch--active' : '' }`}>
      {leftLabel && <label className="switch__leftLabel" onClick={deActivate}>{leftLabel}</label>}
      <div className="switch__toggle" onClick={toggle}>
        <span></span>
      </div>
      {rightLabel && <label className="switch__rightLabel" onClick={activate}>{rightLabel}</label>}
    </div>
  )
}
