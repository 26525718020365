import './navigation.scss';

import React, { useContext, useEffect, useState } from 'react';

import { FormattedMessage } from 'react-intl';
import ROUTES from '../../../common/constants/routes.constants';
import { KiaContext } from '../../../common/context/main.context';
import GTM from '../../../common/services/gtm.service';
import Button from '../../01_atoms/button/Button';
import LanguageDropdown from '../../02_molecules/languageDropDown/LanguageDropdown';
// import CONTACT from '../../../common/constants/contact.constants';
import {
  useRouteMatch
} from 'react-router-dom';
import Switch from '../../02_molecules/switch/Switch';

export default function Navigation({ onClick }) {
  const {
    // locales,
    // mobile,
    currentLocale
    // dealer
  } = useContext(KiaContext);
  // const params = useParams();
  const match = useRouteMatch();

  // const [ showTestdrive, setShowTestdrive ] = useState(false);
  const [ showCarsMenu, setShowCarsMenu ] = useState(false);

  // const renderTestdriveButton = () => {
  //   if (!showTestdrive) { return null; }

  //   if (dealer && dealer.properties.user_properties.testdrive_code) {
  //     return <Button mode="outline" href={`${currentLocale.testdrive}&e=${dealer.properties.user_properties.testdrive_code}`} size={mobile ? `xsmall` : 'small'}><FormattedMessage id="contact.testdrive" /></Button>
  //   }
  //   return <Button mode="outline" href={currentLocale.testdrive} size={mobile ? `xsmall` : 'small'}><FormattedMessage id="contact.testdrive" /></Button>;
  // };

  useEffect(() => {
    // setShowTestdrive(match.path.indexOf(`${ROUTES.prefix}${ROUTES.contact}`) < 0 && match.path.indexOf(`${ROUTES.prefix}${ROUTES.cars}`) < 0);
    if (currentLocale.locale === 'fr-lu') {
      setShowCarsMenu(match.path.indexOf(`${ROUTES.cars}`) >= 0);
    } else {
      setShowCarsMenu(match.path.indexOf(`${ROUTES.prefix}${ROUTES.cars}`) >= 0);
    }
  }, [match]);

  return (
    <nav className="navigation">
      <B2bBanner />
      <ToggleB2cB2b />
      {/* <ToggleB2cB2b /> */}
      <div className="navigation__container">
        { showCarsMenu ? <DetailNavigation {...{locale: currentLocale.locale, allCarsOnClickHandler: onClick}}  /> : null }
        <div className="navigation__container__language"><LanguageDropdown /></div>
      </div>
    </nav>
  );
}


const DetailNavigation = ({locale, allCarsOnClickHandler}) => {
  return <Button mode="naked" size="small" color="light" onClick={allCarsOnClickHandler} className={`navigation__container__button navigation__carlist__button mr--2 ${locale === 'fr-lu' ? 'no-right-border' : ''}`}><FormattedMessage id="carswitch.toggle" /></Button>
}

const ToggleB2cB2b = () => {
  const { isB2c, otherPlatformUrl, hideToggle } = useContext(KiaContext);
  const [isRedirecting, setIsRedirecting] = useState(false)
  const onClickHandler = (isB2b) => {
    if (isRedirecting) return false
    if (isB2c !== !isB2b) {
      GTM.track({
        dlCategory: isB2b ? 'B2cToB2b' : 'B2bToB2c',
        dlAction: 'Click',
        dlLabel: '',
      });
      const path = window.location.pathname
      window.location.href=`${otherPlatformUrl}${path}`
      setIsRedirecting(true)
      return false
    }
  }
  const leftLabel = <><span className='navigation__b2bb2c__toggle__prefix'><FormattedMessage id="navigation.toggle.prefix" /> </span><FormattedMessage id="navigation.b2c" defaultMessage="B2C" /></>
  const rightLabel = <><span className='navigation__b2bb2c__toggle__prefix'><FormattedMessage id="navigation.toggle.prefix" /> </span><FormattedMessage id="navigation.b2b" defaultMessage="B2B" /></>
  const isB2b = !isB2c

  if (hideToggle) return null;

  return <div className="navigation__b2bb2c mr--2"><div className="navigation__b2bb2c__switch"><Switch active={isB2b} onClick={onClickHandler} leftLabel={leftLabel} rightLabel={rightLabel} /></div></div>
}

const B2bBanner = () => {
  const { isB2c } = useContext(KiaContext);

  if (isB2c) return null;

  return <div className="navigation__banner">
    <FormattedMessage id="navigation.banner.text_1" />
    <strong><FormattedMessage id="navigation.banner.text_2" /></strong>
    <FormattedMessage id="navigation.banner.text_3" />
  </div>
}
