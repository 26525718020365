import React from 'react';
import { FormattedMessage } from 'react-intl';
// import Icon from '../../01_atoms/icons/Icon';
import './engine.scss';

export default function Engine(props) {
  const { type } = props;
  return (
    <div className={`engine engine--${type}`}>
      {/* <Icon icon={type} className="engine__icon" /> */}
      <p className="p--5 my--0 bold engine__label"><FormattedMessage id={`powertrain.${type}`} /></p>
    </div>
  )
}
