import React, { useEffect, useState } from 'react';
import Button from '../../01_atoms/button/Button';
import Close from '../../01_atoms/icons/close/Close';
import './info.scss';

export default function Info(props) {
  const {
    close,
    onClose,
    children,
    visible,
    hideBG,
  } = props;

  const [ hidden, setHidden ] = useState(!visible);

  const onCloseClicked = (e) => {
    setHidden(true);
    onClose(e);
  };

  useEffect(() => {
    setHidden(!visible);
  }, [visible])

  return (
    <div className={`info section section--2${hidden ? ' info--hidden' : ''}${ hideBG ? ' info--no-bg' : ''}`}>
      <div className="info__content center">
        { close ? (<Button round color="transparent" className="info__close" onClick={onCloseClicked} icon={<Close />} />) : null }

        { children }
      </div>
    </div>
  );
};
