import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Button from '../../01_atoms/button/Button';
import './footer.scss';

export default function Footer(props) {
  const {
    fixed,
    light,
    copy,
  } = props;

  const intl = useIntl();

  return (
    <footer className={`footer${ fixed ? ' footer--fixed' : ''}${ light ? ' footer--light' : ''}`}>
      <div className="footer__content section section--9">
        <div className="footer__navigation">
          <nav className="navigation">
            <ul className="navigation__list">
              <li className="navigation__item"><Button mode="naked" href={intl.formatMessage({ id: 'footer.legal.privacy.link' })} className="navigation__link"><FormattedMessage id="footer.legal.privacy" /></Button></li>
              <li className="navigation__item"><Button mode="naked" href={intl.formatMessage({ id: 'footer.legal.cookies.link' })} className="navigation__link"><FormattedMessage id="footer.legal.cookies" /></Button></li>
              <li className="navigation__item"><Button mode="naked" href={intl.formatMessage({ id: 'footer.legal.terms.link' })} className="navigation__link"><FormattedMessage id="footer.legal.terms" /></Button></li>
              <li className="navigation__item"><Button mode="naked" href={intl.formatMessage({ id: 'footer.legal.company.link' })} className="navigation__link"><FormattedMessage id="footer.legal.company" /></Button></li>
            </ul>
          </nav>
        </div>
        { copy ? (
          <div className="footer__copy">
            { copy }
          </div>
        ) : null }
      </div>
    </footer>
  );
}
