import React, { useContext, useEffect, useState } from 'react';
import { useParams, useRouteMatch } from 'react-router-dom';
import { KiaContext } from '../../../common/context/main.context';
import GTM from '../../../common/services/gtm.service';
import Button from '../../01_atoms/button/Button';
import Icon from '../../01_atoms/icons/Icon';
import './languageDropdown.scss';

export default function LanguageDropdown({ onClick }) {

  const { locales, mobile } = useContext(KiaContext);
  const [ openLanguage, setOpenLanguage ] = useState(false);
  const [ selectedLanguage, setSelectedLanguage ] = useState("");

  const params = useParams();
  const match = useRouteMatch();

  const onLanguageClick = (slug) => {
    GTM.track({
      dlCategory: 'Language',
      dlAction: 'Click',
      dlLabel: slug,
    });
    setSelectedLanguage(slug);
  };

  const handleDropdownToggleClick = () => {
    setOpenLanguage((prevOpen) => !prevOpen);
  };


  useEffect(() => {
    setSelectedLanguage(params.lang);
  }, [params.lang]);

  return (
    <>
      { locales.length > 1 ? (
      <div className="language__wrappper">
        <div className="language__list__wrapper">
          <Button color="light" icon={<Icon icon="arrow-down"/>} mode="naked" size={mobile ? `xsmall` : `small`} open={openLanguage} onClick={handleDropdownToggleClick} className="uppercase button--icon-after">{selectedLanguage}</Button>

          <ul className={`language__list${ openLanguage ? ' open' : '' }`}>
            { locales.map((language) => {
              const url = match.url.replace(params.lang, language.slug);
              return (
                <li className="language__item mr--4" key={language.name}>
                  <Button
                    onClick={() => onLanguageClick(language.slug)}
                    color="light"
                    mode="naked"
                    size={mobile ? `xsmall` : 'small'}
                    href={url}
                    target="_self"
                    className={`language__link${params.lang === language.slug ? ' language__link--active' : ''}`}
                  >{ language.short }</Button>
                </li>
              );
              })
            }
          </ul>
        </div>
      </div>
      ) : null }
    </>
  );
}
