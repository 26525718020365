import React from 'react';
import { Link, useParams } from 'react-router-dom';
import GTM from '../../../common/services/gtm.service';
import { ReactComponent as SVGLogo } from './graphics/kia_logo.svg';
import './logo.scss';

export default function Logo() {
  const { lang = '' } = useParams();

  const onLogoClick = () => {
    GTM.track({
      dlCategory: 'Logo',
      dlAction: 'Click',
      dlLabel: '',
    });
  }

  return (
    <div className="logo__wrapper">
      <Link to={`/${lang}`} className="logo__link" onClick={onLogoClick}>
        <SVGLogo className="logo" />
      </Link>
    </div>
  )
}
