import axios from 'axios';
import CONFIG from '../config';

export const getCampaigns = (locale) => axios.get(`${CONFIG.API_BASE}/campaigns`, {
  params: { locale, v: CONFIG.VERSION }
}).then(res => res.data).then(res => {
  const campaigns = res.data || [];

  if (!campaigns || !campaigns.length) {
    campaigns.push({
      "id": 0,
      "hotspot": [{
        "yaw": "-0.25",
        "pitch": ".005",
        "rotation": "0",
      }]
    });
  }

  return campaigns.map(campaign => {
    if (campaign.video) {
      // eslint-disable-next-line
      const regex = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
      const match = campaign.video.match(regex);

      if (match && match[7]) {
        campaign.video = `https://www.youtube-nocookie.com/embed/${match[7]}?controls=0&autoplay=1&muted=1&disablekb=1&loop=1&modestbranding=1&playsinline=1&rel=0`;
        campaign.youtube = true;
      }
    }

    return campaign;
  });
});

export const getCampaign = (locale, id) => axios.get(`${CONFIG.API_BASE}/campaigns/${id}`, {
  params: { locale }
}).then(res => res.data);
