import './index.scss';

import * as serviceWorker from './serviceWorker';

import React from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import CONFIG from './common/config';

window.dlVirtualPageviewCounter = 1;

// adapted from serviceworker
const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    ) ||
    // 192.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname.match(
      /^192(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

function getCountry() {
  const countries = CONFIG.COUNTRIES;
  const domain = window.location.hostname;
  const domainPort = window.location.port;

  let country;

  if (CONFIG.DEBUG) { console.log('Available countries', countries, domain); }

  if (isLocalhost) {
    country = countries[0];
  } else {
    country = countries.find(c => c.domain === domain || c.domain === `${domain}:${domainPort}` || c.configurator === domain || c.configurator === `${domain}:${domainPort}`);
  }

  if (CONFIG.DEBUG) { console.log('Selected country:', country); }
  return country;
};

const country = getCountry();

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <HelmetProvider>
        <App country={country} />
      </HelmetProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorker.unregister();
