import axios from 'axios';
import CONFIG from '../config';

const time = (new Date()).getTime();

export const getSiteSettings = (locale) => axios.get(`${CONFIG.API_BASE}/settings/site?locale=${locale}&v=${CONFIG.VERSION}`).then(res => res.data).then(res => res.data);
export const getMetaSettings = (locale) => axios.get(`${CONFIG.API_BASE}/settings/metatags?locale=${locale}&v=${CONFIG.VERSION}`).then(res => res.data).then(res => res.data);
export const getHomeSettings = (locale) => axios.get(`${CONFIG.API_BASE}/settings/home?locale=${locale}&v=${CONFIG.VERSION}`).then(res => res.data).then(res => res.data);
export const getZendeskSettings = () => axios.get(`${CONFIG.API_BASE}/settings/zendesk?v=${CONFIG.VERSION}`).then(res => res.data).then(res => res.data);
export const getBMSSettings = (locale) => axios.get(`${CONFIG.API_BASE}/settings/bms?locale=${locale}&v=${CONFIG.VERSION}`).then(res => res.data).then(res => res.data);
export const getCookiesSettings = () => axios.get(`${CONFIG.API_BASE}/settings/cookies?v=${CONFIG.VERSION}`).then(res => res.data).then(res => res.data);
export const getLegalSettings = (locale) => axios.get(`${CONFIG.API_BASE}/settings/legal?locale=${locale}&v=${CONFIG.VERSION}`).then(res => res.data).then(res => {
  const { data } = res;

  try {
    if (CONFIG.DEBUG && !data.representative_example) {
      data.representative_example = `
        Lening op afbetaling. Kredietbedrag: € 17.788,51. Voorschot (facultatief) : € 5.358,10. Contante prijs : € 23.146,61. JKP (Jaarlijks Kostenpercentage) van 1,99 %, vaste jaarlijkse debetrentevoet: 1,97 %. Terug te betalen in 60 maandelijkse aflossingen van € 311,57 . Actie geldig van 01/10/2020 tot 31/10/2020. Totaal terug te betalen bedrag: € 18.694,20. Looptijd beperkt tot 60 maanden.

        Lening op afbetaling. Kredietbedrag: 17.788,51 €. Voorschot (facultatief) : 5.358,10 €. Contante prijs : 23.146,61 €. JKP (Jaarlijks Kostenpercentage) van 1,99 %, vaste jaarlijkse debetrentevoet: 1,97 %. Terug te betalen in 60 maandelijkse aflossingen van 311,57 € . Actie geldig van 01/10/2020 tot 31/10/2020. Totaal terug te betalen bedrag: 18.694,20 €. Looptijd beperkt tot 60 maanden.

        Onder voorbehoud van aanvaarding van uw kredietaanvraag door Alpha Credit n.v., kredietgever, St-Lazaruslaan 4-10/3 te 1210 Brussel, BTW BE 0445.781.316, RPR Brussel. Adverteerder: Kia Motors Belgium n.v., agent in nevenfunctie, Ikaroslaan 33, 1930 Zaventem, BE 0477.443.106, IBAN: BE17 5701 3129 5521, RPR Brussel.
      `;
    }

    // const exRegex = /(€\s)?((\d+\.)?\d+,\d+)+(\s?[€%])?/gmi;
    // const representative_example = data.representative_example.replace(exRegex, '<strong class="legal">$&</strong>');

    // data.representative_example = representative_example;
  } catch(e) {
    if (CONFIG.DEBUG) {
      console.warn('Unable to highlight legal prices.');
    }
  }

  return data;
});
const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const maintenanceHash = urlParams.get('preview')
export const getMaintenanceSettings = () => axios.get(`${CONFIG.API_BASE}/settings/maintenance?v=${CONFIG.VERSION}_${time}&maintenancehash=${encodeURIComponent(maintenanceHash)}`).then(res => res.data).then(res => res.data);
export const getMonthlyPricesSettings = () => axios.get(`${CONFIG.API_BASE}/settings/monthlyprices?v=${CONFIG.VERSION}_${time}`).then(res => res.data).then(res => res.data);
export const getMaintenanceStatus = (locale) => axios.get(`${CONFIG.OTHER_API_BASE}/settings/status?locale=${locale}&v=${CONFIG.VERSION}`).then(res => res.data).then(res => res.data).catch(() => "0");
