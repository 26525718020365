import React, { useContext, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, useParams } from 'react-router-dom';
import CONFIG from '../../../common/config';
import ROUTES from '../../../common/constants/routes.constants';
import { KiaContext } from '../../../common/context/main.context';
import GTM from '../../../common/services/gtm.service';
import { formatPrice } from '../../../common/utils/price.utils';
// import Icon from '../../01_atoms/icons/Icon';
// import Eswitch from '../../02_molecules/eswitch/Eswitch';
import './allcarsswitch.scss';
// import Button from '../../01_atoms/button/Button';
import { useHistory } from 'react-router';

export default function AllCarsswitch(props) {
  const {
    activeCar,
    onClick = () => {},
    open,
  } = props;
  const params = useParams();

  const { cars, prefix, currentLocale, isB2c } = useContext(KiaContext);
  // const [ open, setOpen ] = useState(handleOpen);
  const [ onlyElectric, /*setOnlyElectric*/ ] = useState(false);

  const activeCars = cars.filter(car => !car.cta);

  const history = useHistory();

  // const onOpen = () => {
  //   const _open = !handleOpen;

  //   const trackPayload = {
  //     dlCategory: 'View all models',
  //     dlAction: 'Open',
  //   };

  //   if (activeCar && activeCar.slug) {
  //     trackPayload.dlModel = activeCar.slug;
  //   }

  //   GTM.track(trackPayload);

  //   setOpen(_open);
  // };

  const onCarClick = (car) => {
    // setOpen(false);

    const trackPayload = {
      dlCategory: 'View all models',
      dlAction: 'Click',
      dlLabel: car.slug,
    };

    if (activeCar && activeCar.slug) {
      trackPayload.dlModel = activeCar.slug;
    }

    GTM.track(trackPayload);

    onClick();
    history.push(`${prefix}${ROUTES.cars}/${car.slug}`);
  }

  // const onSwitchClick = (isElectric) => {
  //   setOnlyElectric(isElectric);

  //   const trackPayload = {
  //     dlCategory: 'View all models',
  //     dlAction: 'Click',
  //     dlLabel: isElectric ? 'on' : 'off',
  //   };

  //   if (activeCar && activeCar.slug) {
  //     trackPayload.dlModel = activeCar.slug;
  //   }

  //   GTM.track(trackPayload);
  // }

  return (
    <div className={`allcarsswitch${ open ? ' open' : '' }${ onlyElectric ? ' allcarsswitch--electric' : '' }`}>
      {/* <div className="allcarsswitch__toggle" onClick={onOpen}>
        <span><FormattedMessage id="allcarsswitch.toggle" /></span>
        <Icon icon="arrow-down" />
      </div> */}
      {/* <Button mode="outline" size="small" onClick={onOpen}><FormattedMessage id="carswitch.toggle" /></Button> */}
      <div className="allcarsswitch__content">
        { activeCars.map(car => (
          <Link onClick={() => onCarClick(car)} to={`${prefix}${ROUTES.cars}/${car.slug}`} className={`allcarsswitch__item${ car.slug === params.slug ? ' allcarsswitch__item--active' : '' }${ car.is_electric ? ' allcarsswitch__item--electric' : '' }`} key={`allcarsswitch-${car.slug}`}>
            <p className="p--3 my--0 bold center allcarsswitch__title">Kia { car.price_default_short_name }</p>
            <picture className="allcarsswitch__visual">
              <img src={car.thumbnail} alt={car.title} />
            </picture>
            { isB2c && (<p className="p--3 my--0 center allcarsswitch__price"><FormattedMessage id="price.from" values={{ price: formatPrice(car.price_motor_show, CONFIG.PRICE_FORMAT, currentLocale.locale)}} /></p>) }
          </Link>
        )) }
      </div>
      {/* <Eswitch active={onlyElectric} onClick={onSwitchClick} /> */}
    </div>
  );
}
