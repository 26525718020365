const CONTACT = {
  quote: 'quote',
  testdrive: 'testdrive',
  tradein: 'tradein',

  values: {
    quote: 'Quote Request',
    testdrive: 'Test Drive Request',
    tradein: 'Trade-In Request',
  },

  codes: {
    quote: process.env.REACT_APP_CAMPAIGN_CODE_QUOTE,
    testdrive: process.env.REACT_APP_CAMPAIGN_CODE_TESTDRIVE,
    tradein: process.env.REACT_APP_CAMPAIGN_CODE_TRADEIN,
  }
};

export default CONTACT;
