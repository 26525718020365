import React from 'react';
import './icon.scss';

import { ReactComponent as View360 } from './graphics/360.svg';
import { ReactComponent as Alert } from './graphics/alert.svg';
import { ReactComponent as ArrowCircleUp } from './graphics/arrow-circle-up.svg';
import { ReactComponent as ArrowDown } from './graphics/arrow-down.svg';
import { ReactComponent as ArrowLeft } from './graphics/arrow-left.svg';
import { ReactComponent as ArrowRight } from './graphics/arrow-right.svg';
import { ReactComponent as ArrowLeftLong } from './graphics/arrow-left-long.svg';
import { ReactComponent as ArrowRightLong } from './graphics/arrow-right-long.svg';
import { ReactComponent as Backdoors } from './graphics/backdoors.svg';
import { ReactComponent as Backseats } from './graphics/backseats.svg';
import { ReactComponent as Check } from './graphics/check.svg';
import { ReactComponent as Close } from './graphics/close.svg';
import { ReactComponent as Exterior } from './graphics/exterior.svg';
import { ReactComponent as Frontdoors } from './graphics/frontdoors.svg';
import { ReactComponent as Frontseats } from './graphics/frontseats.svg';
import { ReactComponent as Key } from './graphics/key.svg';
import { ReactComponent as Lights } from './graphics/lights.svg';
import { ReactComponent as Link } from './graphics/link.svg';
import { ReactComponent as Offer } from './graphics/offer.svg';
import { ReactComponent as Steeringwheel } from './graphics/steeringwheel.svg';
import { ReactComponent as Trade } from './graphics/trade.svg';
import { ReactComponent as Trunk } from './graphics/trunk.svg';
import { ReactComponent as Chat } from './graphics/chat.svg';
import { ReactComponent as More } from './graphics/more.svg';
import { ReactComponent as Less } from './graphics/less.svg';
import { ReactComponent as Electric } from './graphics/electric.svg';
import { ReactComponent as Hybrid } from './graphics/hybrid.svg';
import { ReactComponent as Plughybrid } from './graphics/plughybrid.svg';
import { ReactComponent as Mildhybrid } from './graphics/mildhybrid.svg';
import { ReactComponent as Essence } from './graphics/essence.svg';
import { ReactComponent as Video } from './graphics/video.svg';
import { ReactComponent as Car360 } from './graphics/show-car-360.svg';
import { ReactComponent as ConfigExterior } from './graphics/config-exterior.svg';
import { ReactComponent as ConfigFrontdoor } from './graphics/config-frontdoor.svg';
import { ReactComponent as ConfigLights } from './graphics/config-lights.svg';
import { ReactComponent as ConfigTrunk } from './graphics/config-trunk.svg';
import { ReactComponent as Plus } from './graphics/plus.svg';

export default function Icon(props) {
  const {
    icon,
    className = '',
    ...restProps
  } = props;

  const renderIcon = () => {
    switch (icon) {
      case '360': return (<View360 />);
      case 'alert': return (<Alert />);
      case 'arrow-circle-up': return (<ArrowCircleUp />);
      case 'arrow-down': return (<ArrowDown />);
      case 'arrow-left': return (<ArrowLeft />);
      case 'arrow-right': return (<ArrowRight />);
      case 'arrow-left-long': return (<ArrowLeftLong />);
      case 'arrow-right-long': return (<ArrowRightLong />);
      case 'backdoors': return (<Backdoors />);
      case 'backseats': return (<Backseats />);
      case 'check': return (<Check />);
      case 'close': return (<Close />);
      case 'exterior': return (<Exterior />);
      case 'frontdoors': return (<Frontdoors />);
      case 'frontseats': return (<Frontseats />);
      case 'key': return (<Key />);
      case 'lights': return (<Lights />);
      case 'link': return (<Link />);
      case 'quote':
      case 'offer': return (<Offer />);
      case 'steeringwheel': return (<Steeringwheel />);
      case 'trade': return (<Trade />);
      case 'trunk': return (<Trunk />);
      case 'chat': return (<Chat />);
      case 'more': return (<More />);
      case 'less': return (<Less />);
      case 'ev': return (<Electric />);
      case 'hev': return (<Hybrid />);
      case 'phev': return (<Plughybrid />);
      case 'mhev': return (<Mildhybrid />);
      case 'ice_sessence':
      case 'ice_diesel': return (<Essence />);
      case 'video': return (<Video />);
      case 'play': return (<Video />);
      case 'car360': return (<Car360 />);
      case 'configexterior': return (<ConfigExterior />);
      case 'configfrontdoor': return (<ConfigFrontdoor />);
      case 'configlights': return (<ConfigLights />);
      case 'configtrunk': return (<ConfigTrunk />);
      case 'plus': return (<Plus />);

      default: return null;
    }
  }

  return (
    <span className={`icon icon--${icon}${className ? ` ${className}` : ''}`} {...restProps}>
      { renderIcon() }
    </span>
  )
}
