import './page.scss';

import React, { useContext, useEffect, useState } from 'react';

import { Helmet } from 'react-helmet-async';
import { KiaContext } from '../../../common/context/main.context';
import GTM from '../../../common/services/gtm.service';
import Chat from '../../02_molecules/chat/Chat';
import Footer from '../../03_organisms/footer/Footer';
import Header from '../../03_organisms/header/Header';
import HeaderSimple from '../../03_organisms/header/HeaderSimple';

export default function Page(props) {
  const context = useContext(KiaContext);
  const { showZendesk, meta, currentLocale, setupCookieScript } = context;

  const _title = meta.site_name;
  const _meta_title = meta.meta_title;
  const _meta_description = meta.meta_description;

  const {
    chat,
    children,
    className = '',
    fixedFooter,
    fixedHeader,
    footer,
    lightFooter,
    lightHeader,
    name = '',
    section,
    simpleHeader,
    metaDescription = _meta_description,
    metaTitle = _meta_title,
    title = _title,
    dlLabel = '',
    dlModel = 'not available',
    footerCopy = '',
    ...restProps
  } = props;

  const classes = ['page', `page--${name}`, ...className.split(',')].filter(r => r);

  const [ _meta, setMeta ] = useState({
    title,
    metaTitle,
    metaDescription,
  });

  useEffect(() => {
    setMeta({
      title,
      metaTitle,
      metaDescription,
    })
  }, [title, metaTitle, metaDescription]);

  useEffect(() => {
    const isConfigurator = window.location.href.indexOf('/configurator/') >= 0;
    if (!isConfigurator) {
      setupCookieScript(currentLocale);
    }

    GTM.track({
      event: 'dlVirtualPageviewEvent',
      dlLabel,
      dlModel
    });

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });

  }, [dlLabel]);

  return (
    <div className={classes.join(' ')} {...restProps}>
      <Helmet>
        <title>{ _meta.title }</title>
        <meta property="og:site_name" content={ _meta.title } />
        <meta property="og:title" content={ _meta.metaTitle } />
        <meta property="og:description" content={ _meta.metaDescription } />
      </Helmet>
      { simpleHeader ? (<HeaderSimple />) : (<Header fixed={fixedHeader} light={lightHeader} />) }
      <div className={`page__inner${section ? ' section' : ''}`}>
        { children }
      </div>
      { chat && showZendesk ? (<Chat />) : null }
      { footer ? <Footer fixed={fixedFooter} light={lightFooter} copy={footerCopy} /> : null }
    </div>
  )

}
