const decode = (query = '') => query.substring(1).split('&').reduce((result, param) => {
    const _param = param.split('=');
    result[_param[0]] = _param[1];
    return result;
  }, {});

const encode = (object = {}) => '?' + Object.keys(object).reduce((result, key) => {
    result.push(`${key}=${object[key]}`);
    return result;
  }, []);

const querystring = {
  decode,
  encode,
};

export function useQuery() {
  return new URLSearchParams(window.location.search);
}

export default querystring;
