import React from 'react';
import CONFIG from '../../../common/config';
import img_fallback from './graphics/kia-company-logo.jpg';
import './campaign.scss';

export default function Campaign(props) {
  const {
    iframe,
    video,
    youtube,
  } = props;

  if (CONFIG.DEBUG) { console.log('render campaign', props); }

  const renderVideo = () => youtube ? (
    <div className="campaign__video">
      { /* eslint-disable-next-line */ }
      <iframe width="720" height="540" src={ video } frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    </div>
  ) : (
    <div className="campaign__video">
      { /* eslint-disable-next-line */ }
      <video src={ video } muted playsInline autoPlay loop />
    </div>
  );
  const renderIframe = () => (
    <div className="campaign__iframe">
      { /* eslint-disable-next-line */ }
      <iframe width="540" height="720" src={ iframe } frameBorder="0"></iframe>
    </div>
  );

  const renderFallback = () => (
    <div className="campaign__image">
      <img src={img_fallback} alt="Kia logo" />
    </div>
  );

  return (
    <div className="campaign">
      { !video && !iframe ? renderFallback() : null }
      { video ? renderVideo() : null }
      { iframe ? renderIframe() : null }
    </div>
  );
}
