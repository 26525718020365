import React from 'react';
import './box.scss';

export default function Box(props) {
  const {
    className = '',
    children,
    ...extraProps
  } = props;

  const classes = ['box', ...className.split(' ')];

  return (
    <div className={classes.join(' ')} {...extraProps}>
      <div className="box__content">
        { children }
      </div>
    </div>
  );
}
