import CONTACT from "./contact.constants";

const ROUTES = {
  language:     '/',
  prefix:       '/:lang',
  showroom:     '/',
  electric:     '/electric',
  cars:         '/cars',
  carsDetail:   '/cars/:slug',
  stories:      '/stories',
  storiesDetail:'/stories/:slug',
  contact:      '/contact',
  contactDetail:'/contact/:source',
  quote:        `/contact/${CONTACT.quote}`,
  testdrive:    `/contact/${CONTACT.testdrive}`,
  tradein:      `/contact/${CONTACT.tradein}`,
  pages:        '/pages',
  pagesDetail:  '/pages/:slug',
  notfound:     '/notfound'
};

export default ROUTES;
