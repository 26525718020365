import './priceinfoprice.scss';

import { FormattedMessage, useIntl } from "react-intl";

import CONFIG from "../../../common/config"
import React from 'react';
import { formatPrice } from "../../../common/utils/price.utils"

export default function PriceinfoPrice({isB2c, car, locale, type}) {
    const intl = useIntl()
    
    if (isB2c && type === 'kia_private_lease') return null
    if (!isB2c && type === 'kia_lease') return null

    const typeMonth = intl.formatMessage({id: `${type}.month`});
    if (isB2c && type === 'easymove') return <p className="mt--2 mb--3 priceinfo__easymove"><FormattedMessage id="powertrain.easymove" />:&nbsp;<FormattedMessage id={`from`} />&nbsp;<span>{ formatPrice(car.easymove.price, CONFIG.PRICE_FORMAT, locale) } / <FormattedMessage id="powertrain.month" /></span></p>
    
    return <p className={`mt--2 mb--3 priceinfo__${type}`}><FormattedMessage id={`${type}.label`} />&nbsp;<FormattedMessage id={`from`} />&nbsp;<span>{ formatPrice(car?.[type]?.price, CONFIG.PRICE_FORMAT, locale) } / <span dangerouslySetInnerHTML={{__html: typeMonth}} /></span></p>
}