import React from 'react';
import Lottie from 'lottie-react';
import './loading.scss';
import loaderData from './loader.json';

export default function Loading({ page, text }) {

  const isConfigurator = window.location.href.indexOf('/configurator/') >= 0;

  return isConfigurator ? null : (
    <div className={`loading${page ? ' page--loading' : ''}`}>
      <div className="loading__inner">
        <Lottie className="loading__animation" animationData={loaderData} />
        { text ? (<div className="fc--text-light">{ text }</div>) : null }
      </div>
    </div>
  );
}
