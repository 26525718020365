import CONFIG from "../config";
import STORAGE from "../constants/storage.constants";

const track = (props) => {
  const { dataLayer } = window;
  const dealer = sessionStorage.getItem(STORAGE.dealer);

  const payload = {
    event: 'dlEvent',
    dlPlatform: Number(CONFIG.IS_B2C) === 1 ? 'B2C' : 'B2B',
    ...props
  }

  if (dealer) {
    payload.dlDealer = dealer;
  }

  if (props.event === 'dlVirtualPageviewEvent') {
    payload.dlVirtualPageviewCounter = window.dlVirtualPageviewCounter;
    window.dlVirtualPageviewCounter += 1;
  }

  if (CONFIG.DEBUG) {
    console.log('GTM datalayer.push', payload);
    return;
  }

  // track with GTM
  dataLayer.push(payload);
};

const GTM = {
  track,
};

export default GTM;
