import React, { useContext, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { KiaContext } from '../../../common/context/main.context';
import Button from '../../01_atoms/button/Button';
import Icon from '../../01_atoms/icons/Icon';
import Box from '../box/Box';
import './promo.scss';

export function PromoOffer(props) {
  const {
    content = 'promo.content.home',
    showCTA,
    showLegal,
    showClose,
    indicator = '',
    border = true,
  } = props;

  const { country } = useContext(KiaContext);

  const [open, setOpen] = useState(false);
  const [close, setClose] = useState(false);

  const intl = useIntl();

  // @todo check in feb to update to better solution
  const month = new Date().getMonth() + 1;
  let day = new Date().getDate();
  let showOther = month === 2;

  if (country.id === 'lu' && day < 4) {
      showOther = false;
  }

  let promoLegal = intl.formatMessage({ id: 'promo.legal' });
  if (showOther) {
    promoLegal = intl.formatMessage({ id: 'promo.legal.feb' });
  }

  const toggleOpen = () => {
    setOpen(!open);
  };

  const toggleClose = () => {
    setClose(!close);
  }

  return !close && (
    <Box className={`promo promo--offer`}>
      { showClose && (
        <div className="promo__close">
          <Button color='light' mode='naked' size='small' icon={<Icon icon="close" />} onClick={toggleClose}  />
        </div>
      )}

      <div className={`promo__wrapper${border ? ' show-border' : ''}${showClose ? ' show-close' : ''}`}>
        <div className="promo__content">
          <p><span dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: content}) }} />&nbsp;{ indicator }</p>
          { showCTA && (<Button to={intl.formatMessage({ id: 'promo.url' })} color='light' mode='outline' size='small'><FormattedMessage id="promo.cta" /></Button>) }
        </div>
        {showLegal && (
          <div className={`promo__footer${ open ? ' is-open' : '' }`}>
            <div className="promo__trigger" onClick={toggleOpen}><Icon icon="arrow-down" /></div>
            <div className="promo__legal" dangerouslySetInnerHTML={{ __html: promoLegal }} />
          </div>
        ) }
      </div>
    </Box>
  );
}
