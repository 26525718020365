import { Redirect, Route, Switch } from 'react-router-dom';

import CONFIG from '../config';
import Car from '../../ui/05_buckets/car/Car';
import CarConfigurator from '../../ui/05_buckets/carConfigurator/CarConfigurator';
import Contact from '../../ui/05_buckets/contact/Contact';
import { KiaContextProvider } from '../context/main.context';
import Language from '../../ui/05_buckets/language/Language';
import NotFound from '../../ui/05_buckets/notfound/NotFound';
import ROUTES from '../constants/routes.constants';
// import PageDetail from '../../ui/05_buckets/pagedetail/PageDetail';
import React from 'react';
import STORAGE from '../constants/storage.constants';
import Showroom from '../../ui/05_buckets/showroom/Showroom';
import { useQuery } from '../utils/querystring.utils';

export default function Routes({ locales, country }) {
  const query = useQuery();

  const dealer = query.get('dealer');

  if (dealer) {
    sessionStorage.setItem(STORAGE.dealer, dealer);
  }

  const renderRoutes = (prefix = '', lang = '') => {
    return (
      <Switch>
        <Route path={`${prefix}${ROUTES.showroom}`} exact>
          <Showroom />
        </Route>
        <Route path={`${prefix}${ROUTES.cars}/:slug/configurator/:hash`} exact>
          <CarConfigurator />
        </Route>
        <Route path={`${prefix}${ROUTES.carsDetail}`} exact>
          <Car />
        </Route>
        <Route path={`${prefix}${ROUTES.cars}`} exact>
          <Redirect to={`/${lang}`} />
        </Route>
        <Route path={`${prefix}${ROUTES.contactDetail}`} exact>
          <Contact />
        </Route>
        <Route path={`${prefix}${ROUTES.contact}`} exact>
          <Redirect to={`/${lang}${ROUTES[CONFIG.DEFAULT_CONTACT_SOURCE]}`} />
        </Route>
        {/* <Route path={`${prefix}${ROUTES.pagesDetail}`} exact>
          <PageDetail />
        </Route>
        <Route path={`${prefix}${ROUTES.pages}`} exact>
          <Redirect to={`/${lang}`} />
        </Route> */}
        <Route path={`${prefix}/*`}>
          <NotFound />
        </Route>
      </Switch>
    );
  }

  // no languages found
  if (!locales.length) {
    return (<NotFound />);
  }

  // only 1 language supported
  if (locales.length === 1) {
    return (
      <KiaContextProvider selectedLanguage={locales[0].name} locales={locales} country={country}>
        { renderRoutes() }
      </KiaContextProvider>
    );
  }

  // multiple languages supported
  return (
    <Switch>
      <Route path={ROUTES.language} exact>
        <Language locales={locales} />
      </Route>
      <Route path={ROUTES.prefix} children={({ match }) => {
          // redirect to language selection if language is not supported
          if (!match) { return (<Redirect to={ROUTES.language} />); }

          const { params } = match;
          const lang = locales.find(({ slug }) => slug === params.lang);

          const supported = params.lang && lang;

          if (!supported) { return (<Redirect to={ROUTES.language} />); }

          return (
            <KiaContextProvider selectedLanguage={lang.name} locales={locales} country={country}>
              { renderRoutes(ROUTES.prefix, params.lang) }
            </KiaContextProvider>
          );
        }
      } />
    </Switch>
  );
}
