import React from 'react';
import { Link } from 'react-router-dom';
import Icon from '../icons/Icon';
import './hotspot.scss';

export default function Hotspot(props) {
  const {
    to = '',
    pulse,
    children,
    modifier = '',
    ...restProps
  } = props;

  const Wrap = ({ children, ...restProps }) => to ? (<Link to={to} {...restProps}>{ children }</Link>) : (<div {...restProps}>{ children }</div>);

  return (
    <div className={`hotspot${modifier ? ` hotspot--${modifier}` : ''}`} {...restProps}>
      <Wrap to={to} className="hotspot__inner">
        { pulse ? (
          <div className="hotspot__icon">
            <span />
            <span />
            <span />
            <Icon icon="plus" />
          </div>
        ) : null }

        { children }
      </Wrap>
    </div>
  );
}
