import './header.scss';

import React, { useContext, useState } from 'react';

import AllCarsswitch from '../allcarsswitch/AllCarsswitch';
import { Helmet } from 'react-helmet-async';
import { KiaContext } from '../../../common/context/main.context';
import Logo from '../../01_atoms/logo/Logo';
import Navigation from '../navigation/Navigation';

export default function Header(props) {
  const { fixed, light } = props;
  const { language: { locale } } = useContext(KiaContext);
  const [ dropdownOpen, setDropDownOpen ] = useState(false);

  const handleDropdownToggleClick = () => {
    setDropDownOpen((prevOpen) => !prevOpen);
  };

  return (
    <header className={`header${ fixed ? ' header--fixed' : ''}${ light ? ' header--light' : '' }`}>
      <Helmet>
        <meta property="og:url" content={window.location.href} />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <div className="section section--9">
        <div className="header__content">
          <div className="header__logo">
            <Logo />
          </div>
          { locale ? (<Navigation onClick={handleDropdownToggleClick}/>) : null }
        </div>
      </div>
      <div className="header__devider mt--4"></div>
      <AllCarsswitch open={dropdownOpen} onClick={handleDropdownToggleClick}/>
    </header>
  );
}
