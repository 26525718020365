import React, { useEffect, useState } from 'react';
import Icon from '../icons/Icon';
import './form.scss';
import './select.scss';

export default function Select(props) {
  const {
    wrapperClassName = '',
    className = '',
    label,
    options = [],
    id,
    name,
    hasError,
    errorMessage = '',
    onChange = () => {},
    ...restProps
  } = props;

  const [ showError, setShowError ] = useState(hasError);

  const classes = ['form__select', ...className.split(' ')];
  const wrapperClasses = ['form__item', `form__item--select`, ...wrapperClassName.split(' ')];

  const onValueChange = (e) => {
    // const { value } = e.target;

    setShowError(!e.target.checkValidity());

    onChange(e);
  }

  useEffect(() => {
    if (hasError && !showError) {
      setShowError(hasError);
    }
  }, [hasError]);

  return (
    <div className={`${wrapperClasses.join(' ')}${ showError ? ' form__item--error' : '' }`}>
      { label ? (<label htmlFor={id}>{ label }</label>) : null }
      <Icon icon="arrow-down" />
      <select id={id} className={classes.join(' ')} name={name} {...restProps} onChange={onValueChange}>
        { options.map((option, i) => (<option key={`${name}-${option.value}-${i}`} value={option.value} selected={option.selected} disabled={option.disabled}>{ option.label }</option>)) }
      </select>
      { showError && errorMessage ? (<p className="p--3 fc--negative mt--1 mb--0">{ errorMessage }</p>) : null }
    </div>
  );
}
