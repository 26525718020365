import './priceinfo.scss';

import React, { useContext, useEffect, useRef, useState } from 'react';

import { FormattedMessage } from 'react-intl';
import CONFIG from '../../../common/config';
import { KiaContext } from '../../../common/context/main.context';
import GTM from '../../../common/services/gtm.service';
import { formatPrice } from '../../../common/utils/price.utils';
import Button from '../../01_atoms/button/Button';
import Icon from '../../01_atoms/icons/Icon';
import Popup from '../popup/Popup';

export default function PriceInfo(props) {
  const {
    title,
    subtitle,
    className = '',
    price,
    description,
    actions,
    button,
    buttonLabel = '',
    promo = '',
    model = '',
    isB2c
  } = props;

  const info = useRef();
  const { currentLocale, bms } = useContext(KiaContext);

  const [ open, setOpen ] = useState(false);

  const onTriggerClick = (e) => {
    setOpen(!open);

    GTM.track({
      dlCategory: 'Price details',
      dlAction: 'Click',
      dlLabel: buttonLabel ? title : 'i',
      model,
    });
  };

  // const onActionClick = () => {
  //   setOpen(false);
  // };

  const onDocumentClick = (e) => {
    const isPopupChild = e.target.closest('.popup');

    if (!isPopupChild) {
      setOpen(false);

      window.document.removeEventListener('click', onDocumentClick);
    }
  };

  useEffect(() => {
    if (open) {
      window.document.addEventListener('click', onDocumentClick);
    }
  }, [open]);

  // useEffect(() => {
  //   if (!info || !info.current) { return; }
  //   const $actions = info.current.querySelectorAll('.button');

  //   [...$actions].forEach($action => $action.addEventListener('click', onActionClick));

  //   return () => {
  //     [...$actions].forEach($action => $action.removeEventListener('click', onActionClick));
  //   }
  // }, [info]);

  const hasPriceTakeover = price.takeover && price.takeover !== '0.00'
  const hasPriceDiscount = price.discount && price.discount !== '0.00'
  const hasPriceBonus = price.bonus && price.bonus !== '0.00'
  console.log('has price bonus', price);

  const onlyShowCatalogPrice = price.catalog && !hasPriceDiscount && !hasPriceTakeover

  return (
    <div className={`priceinfo ${className}`} ref={info}>
      { button && buttonLabel ? (
        <Button mode="naked" size="small" underline onClick={onTriggerClick}>{ buttonLabel }</Button>
      ) : (
        <Icon icon="alert" onClick={onTriggerClick} />
      ) }

      { (price || description || actions) ? (
        <Popup name="priceinfo" open={open}>
          <p className="p--1 bold mt--0 mb--1">{ title }</p>
          { subtitle && <p className="priceinfo__subtitle my--0">{ subtitle }</p> }
          { promo ? (<div className="priceinfo__promo" dangerouslySetInnerHTML={{__html: promo}} />) : null }
          { isB2c && <table className="priceinfo__table">
            <tbody>
              { price.catalog ? (
                <tr>
                  <td><FormattedMessage id="priceinfo.catalogprice" /></td>
                  <td className="bold priceinfo__price">{ formatPrice(price.catalog, CONFIG.PRICE_FORMAT, currentLocale.locale) }</td>
                </tr>
              ) : null }
              { !onlyShowCatalogPrice && hasPriceDiscount ? (
                <tr>
                  { bms ? (<td><FormattedMessage id="priceinfo.discountbms" /></td>) : (<td><FormattedMessage id="priceinfo.discount" /></td>) }
                  <td className="bold priceinfo__price">-{ formatPrice(price.discount, CONFIG.PRICE_FORMAT, currentLocale.locale) }</td>
                </tr>
              ) : null }
              { hasPriceBonus ? (
                <tr>
                  <td><FormattedMessage id="priceinfo.bonus" /></td>
                  <td className="bold priceinfo__price">-{ formatPrice(price.bonus, CONFIG.PRICE_FORMAT, currentLocale.locale) }</td>
                </tr>
              ) : null }
              { !onlyShowCatalogPrice && price.netto ? (
                <tr>
                  { hasPriceTakeover ? (
                    <td><FormattedMessage id="priceinfo.nettoprice" /></td>
                  ) : (
                    <td><FormattedMessage id="priceinfo.nettoprice.short" /></td>
                  ) }
                  <td className="bold priceinfo__price">{ formatPrice(price.netto, CONFIG.PRICE_FORMAT, currentLocale.locale) }</td>
                </tr>
              ) : null }
              { !onlyShowCatalogPrice && hasPriceTakeover ? (
                <tr>
                  { model.includes('picanto') ? (<td><FormattedMessage id="priceinfo.recycleprice" /></td>) : (<td><FormattedMessage id="priceinfo.takeoverprice" /></td>) }

                  <td className="bold priceinfo__price">-{ formatPrice(price.takeover, CONFIG.PRICE_FORMAT, currentLocale.locale) }</td>
                </tr>
              ) : null }
              { !onlyShowCatalogPrice && price.salon ? (
                <tr>
                  {
                    bms ? (
                      <td><FormattedMessage id="priceinfo.salonprice.bms" /></td>
                    ) :
                    hasPriceTakeover ? (
                      <td><FormattedMessage id="priceinfo.salonprice" /></td>
                    ) : (
                      <td><FormattedMessage id="priceinfo.salonprice.short" /></td>
                    )
                  }
                  <td className="bold priceinfo__price">{ formatPrice(price.salon, CONFIG.PRICE_FORMAT, currentLocale.locale) }</td>
                </tr>
              ) : null }
            </tbody>
          </table> }

          { props.children }

          { description ? (<div className="my--3 p--2" dangerouslySetInnerHTML={{__html: description}} />) : null }

          { actions ? (<div className="priceinfo__actions">{ actions }</div>) : null}
        </Popup>
      ) : null }
    </div>
  );
}
