import React, { useEffect } from 'react';
import GTM from '../../../common/services/gtm.service';
import LanguageList from '../../02_molecules/languageList/LanguageList';
import HeaderSimple from '../../03_organisms/header/HeaderSimple';
import './language.scss';

export default function Language({ locales }) {
  useEffect(() => {
    GTM.track({
      event: 'dlVirtualPageviewEvent',
      dlLabel: 'Choose language',
    });
  }, []);

  return (
    <div className="page page--language">
      <HeaderSimple fixed />
      <div className="page__inner section">
        <LanguageList locales={locales} />
      </div>
    </div>
  );
}
