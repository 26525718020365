import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams, useRouteMatch } from 'react-router-dom';
import ROUTES from '../../../common/constants/routes.constants';
import GTM from '../../../common/services/gtm.service';
import Button from '../../01_atoms/button/Button';
import Icon from '../../01_atoms/icons/Icon';
import './chat.scss';

export default function Chat() {
  const params = useParams();
  const match = useRouteMatch();

  const onClick = () => {
    window.zE('webWidget', 'show');
    window.zE('webWidget', 'open');

    const isCarPage = match.path === `${ROUTES.prefix}${ROUTES.carsDetail}`;

    const trackPayload = {
      dlCategory: 'Chat',
      dlAction: 'Start',
    };

    if (isCarPage) { trackPayload.dlModel = params.slug; }

    GTM.track(trackPayload);
  }

  useEffect(() => {
    if (!window.zE) { return; }
    window.zE('webWidget', 'hide');

    window.zE('webWidget:on', 'close', () => {
      window.zE('webWidget', 'hide');
    });

    return () => {
      window.zE('webWidget', 'show');
    }
  }, [window.zE]);

  return (
    <div className="chat">
      <Button onClick={onClick} size="small" color="light" icon={<Icon icon="chat" />}><FormattedMessage id="chat.trigger" /></Button>
    </div>
  )
}
