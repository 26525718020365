import React from 'react';
import GTM from '../../../common/services/gtm.service';
import Button from '../../01_atoms/button/Button';
import './languageList.scss';

export default function LanguageList({ locales }) {
  const onClick = (slug) => {
    GTM.track({
      dlCategory: 'Language',
      dlAction: 'Click',
      dlLabel: slug,
    });
  };

  return (
    <nav className="languages">
      <ul className="languages__list">
        {
          locales.map(data => (
            <li className="languages__item" key={data.name}>
              <Button onClick={() => onClick(data.slug)} to={`/${data.slug}`} className="languages__link" mode="outline">{ data.language_label }</Button>
            </li>
          ))
        }
      </ul>
    </nav>
  );
}
