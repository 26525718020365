import React from 'react';
import './popup.scss';

export default function Popup({ children, name, className = '', open }) {
  return (
    <div className={`popup popup--${name}${ open ? ' popup--open' : ''}${className ? ` ${className}` : ''}`}>
      <div className="popup__content">
        { children }
      </div>
    </div>
  );
}
