import axios from 'axios';
import CONFIG from '../config';

export const getDealers = (payload) => axios.get('https://api.woosmap.com/stores/search/', {
  params: {
    ...payload,
    key: CONFIG.WOOSMAP_KEY,
  },
}).then(response => response.data);

export const getDealer = async (SAP_DEALER_CODE) => {
  if (!SAP_DEALER_CODE) { return null; }

  const response = await getDealers({ query: `user.sap_dealer_code:${SAP_DEALER_CODE}` });

  try {
    if (response.features.length > 0) {
      const dealer = response.features[0];

      const codes_response = await axios.get('/dealers/dealer_codes.json').then(response => response.data);

      dealer.properties.user_properties.testdrive_code = codes_response[SAP_DEALER_CODE];

      return dealer;
    }
  } catch(e) {
    if (CONFIG.DEBUG) { console.warn('error during dealer code mapping', e); }
  }

  return null;
};
