import React, { createContext, useEffect, useState } from 'react';
import { getBMSSettings, getHomeSettings, getLegalSettings, getMaintenanceStatus, getMetaSettings, getMonthlyPricesSettings, getSiteSettings, getZendeskSettings } from '../services/settings.service';

import { IntlProvider } from 'react-intl';
import Loading from '../../ui/02_molecules/loading/Loading';
import CONFIG from '../config';
import STORAGE from '../constants/storage.constants';
import { getCampaigns } from '../services/campaigns.service';
import { getCars } from '../services/cars.service';
import { getDealer } from '../services/dealers.service';
import { getPages } from '../services/pages.service';
import { getShowroom } from '../services/showroom.service';
import { getTranslations } from '../services/translations.service';

export const KiaContext = createContext({});

export const KiaContextProvider = (props) => {
  const { selectedLanguage, children, locales, country } = props;

  const [ lang, setLang ] = useState(selectedLanguage);
  const [ messages, setMessages ] = useState({});
  const [ loading, setLoading ] = useState(true);
  const [ openMenu, setOpenMenu ] = useState(false);
  const [ mobile, setMobile ] = useState(true);
  const [ cars, setCars ] = useState([]);
  const [ showroom, setShowroom ] = useState();
  const [ pages, setPages ] = useState([]);
  const [ campaigns, setCampaigns ] = useState(null);
  const [ currentLocale, setCurrentLocale ] = useState(null);
  const [ showZendesk, setShowZendesk ] = useState(false);
  const [ bms, setBms ] = useState(false);
  const [ legal, setLegal ] = useState(null);
  const [ home, setHome ] = useState(null);
  const [ meta, setMeta ] = useState(null);
  const [ dealer, setDealer ] = useState(null);
  const [ showMonthlyPrices, setshowMonthlyPrices ] = useState(false);
  const [ hideToggle, setHideToggle ] = useState(false);

  const isB2c = Number(CONFIG.IS_B2C) === 1;

  const getDealerData = async () => {
    const _dealer = sessionStorage.getItem(STORAGE.dealer);

    const dealer = await getDealer(_dealer);

    setDealer(dealer);
  };

  const clearDealer = () => {
    setDealer(null);
    sessionStorage.removeItem(STORAGE.dealer);
  };

  const getMessages = async (lang) => {
    const _messages = await getTranslations(lang);

    setMessages(_messages);
  };

  const getShowroomData = async () => {
    const data = await getShowroom();

    data.scenes = data.scenes.map(scene => {
      scene.infoHotspots = cars.map(car => {
        const hotspot = car.hotspot[0] || {};

        return {
          yaw: parseFloat(hotspot.yaw),
          pitch: parseFloat(hotspot.pitch),
          rotation: parseFloat(hotspot.rotation),
          car: car.id,
          slug: car.slug,
        }
      });

      scene.campaigns = campaigns.map(campaign => {
        const hotspot = campaign.hotspot[0] || {};

        return {
          title: campaign.title,
          video: campaign.video,
          iframe: campaign.iframe,
          radius: CONFIG.CAMPAIGN_RADIUS,
          hotspot: hotspot.id,
          yaw: parseFloat(hotspot.yaw),
          pitch: parseFloat(hotspot.pitch),
          rotation: parseFloat(hotspot.rotation),
          id: campaign.id,
        };
      })

      return scene;
    });

    if (CONFIG.DEBUG) { console.log('GET SHOWROOM DATA', data); }

    setShowroom(data);
  };

  const onResize = () => {
    setMobile(window.matchMedia(`(max-width: ${CONFIG.MOBILE_WIDTH}px)`).matches);
  };

  const getCarsOverview = async () => {
    const cars = await getCars(lang);
    const mp = await getMonthlyPricesSettings();

    if (CONFIG.DEBUG) { console.log('cars overview', cars); }

    const extendedCars = cars.map(car => {
      let engine = car.engine.map(engine => {
        return {
          id: engine.id,
          title: engine.title,
          type: engine.type,
          weight: engine.weight,
          car_id: engine.car_id,
          power: engine.power,
          fuel_consumption: engine.fuel_consumption,
          co_two_emissions: engine.co_two_emissions,
          price: engine.price,
          price_discount: engine.price_discount,
          price_bonus: engine.price_bonus,
          price_net: engine.price_net,
          price_trade_bonus: engine.price_trade_bonus,
          price_motor_show: engine.price_motor_show,
          is_exposed_car: engine.is_exposed_car[lang],
          description: engine.description,
          price_calculator: engine.price_calculator,
          action_valid_to: engine.action_valid_to,
          promo_information: engine.promo_information,
          image: engine.image,
          highlight: engine.highlight,
          inspire: engine.inspire,
          style: engine.style,
          badge: engine.badge,
          easymove: {
            price: engine?.easymove || null,
            amount_to_finance: engine?.amount_loan || null,
            amount_deposit: engine?.amount_deposit || null,
            amount_cash: engine?.amount_cash || null,
            amount_jkp: engine?.amount_jkp || null,
            amount_intrest: engine?.annual_debit_interest?.[lang] || null,
            amount_term: engine?.amount_term || null,
            amount_last_payment: engine?.amount_last_payment || null,
            amount_total: engine?.amount_total || null,
            is_electrical: engine?.type === 'ev' || false
          },
          kia_lease: {
            price: engine?.kia_lease || null,
            co_two_emissions: engine?.kia_lease_co_two_emissions?.[lang] || null,
            duration: engine?.kia_lease_duration?.[lang] || null,
            fuel_consumption: engine?.kia_lease_fuel_consumption?.[lang] || null,
            ind_fisc_value: engine?.kia_lease_ind_fisc_value?.[lang] || null,
            km: engine?.kia_lease_km?.[lang] || null,
            start_date: engine?.kia_lease_start_date?.[lang] || null,
            tco: engine?.kia_lease_tco?.[lang] || null,
            is_electrical: engine?.type === 'ev' || false
          },
          financial_renting: {
            price: engine?.financial_renting || null,
            co_two_emissions: engine?.financial_renting_co_two_emissions?.[lang] || null,
            down_payment: engine?.financial_renting_down_payment?.[lang] || null,
            duration: engine?.financial_renting_duration?.[lang] || null,
            fuel_consumption: engine?.financial_renting_fuel_consumption?.[lang] || null,
            invoiced_amount: engine?.financial_renting_invoiced_amount?.[lang] || null,
            purchase_option_percentage: engine?.financial_renting_purchase_option_percentage?.[lang] || null,
            valid_date: engine?.financial_renting_valid_date?.[lang] || null,
            is_electrical: engine?.type === 'ev' || false
          },
          kia_private_lease: {
            price: engine?.kia_private_lease || null,
            km: engine?.kia_private_lease_km?.[lang] || null,
            duration: engine?.kia_private_lease_duration?.[lang] || null,
            start_date: engine?.kia_private_lease_start_date?.[lang] || null,
            fuel_consumption: engine?.kia_private_lease_fuel_consumption?.[lang] || null,
            co_two_emissions: engine?.kia_private_lease_co_two_emissions?.[lang] || null,
            is_electrical: engine?.type === 'ev' || false
          },
          status: engine?.status === true
        }
      }).filter(engine => engine.status === true)

      let exposedCar = engine.find(e => e.is_exposed_car) || null;

      if (exposedCar) {
        return {
          ...car,
          engine,
          easymove: exposedCar.easymove,
          kia_lease: exposedCar.kia_lease,
          financial_renting: exposedCar.financial_renting
        }
      }

      return {
        ...car,
        engine,
        easymove: null,
        kia_lease: null,
        financial_renting: null
      }
    })
    setCars(extendedCars);
    setshowMonthlyPrices(mp.status === '1');
  }

  const getPagesOverview = async () => {
    const pages = await getPages(lang);

    if (CONFIG.DEBUG) { console.log('pages overview', pages); }

    setPages(pages);
  };

  const getCampaignsOverview = async () => {
    const _campaigns = await getCampaigns(selectedLanguage);

    if (CONFIG.DEBUG) { console.log('campaigns overview', _campaigns); }

    setCampaigns(_campaigns);
  };

  const getMeta = async (locale) => {
    const settingsResponse = await getSiteSettings(locale);
    const metaResponse = await getMetaSettings(locale);

    setMeta({
      site_name: settingsResponse.site_name,
      meta_title: metaResponse.title,
      meta_description: metaResponse.description,
    });
  };

  const getBMS = async () => {
    const data = await getBMSSettings(lang);

    if (data.status === '1') {
      setBms(true);
    }
  };

  const getLegal = async () => {
    const data = await getLegalSettings(lang);

    setLegal(data);
  };

  const getHome = async () => {
    const data = await getHomeSettings(lang)
    setHome(data)
  };

  const getData = async () => {
    getCarsOverview();
    getCampaignsOverview();
    getPagesOverview();
  };

  const getStatus = async () => {
    const data = await getMaintenanceStatus(lang);
    setHideToggle(data === 1);
  };

  const setupZendesk = async () => {
    const settings = await getZendeskSettings();

    if (settings.status !== '1') {
      if (CONFIG.DEBUG) { console.log('Zendesk disabled'); }
      return;
    }
    if (CONFIG.DEBUG) { console.log('Zendesk enabled'); }

    if (document.querySelector('#ze-snippet')) {
      setShowZendesk(true);
      return;
    }

    const script = document.createElement('script');
    script.id = 'ze-snippet';
    script.onload = onZendeskLoaded;
    script.src = 'https://static.zdassets.com/ekr/snippet.js?key=web_widget/kiabelgiumsupport.zendesk.com';

    document.head.appendChild(script);
  };

  const hideZendeskWidget = () => {
    // hide default chat widget
    try {
      window.zE('webWidget', 'hide');
    } catch(e) {
      if (CONFIG.DEBUG) { console.warn('Zendesk not available'); }
    }
  }

  const onZendeskLoaded = () => {
    setShowZendesk(true);
    hideZendeskWidget();
  }

  const setupCookieScript = (locale) => {
    const script = document.createElement('script');
    script.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';
    script.dataset.domainScript=locale.cookieID;
    script.id="script-cookiebar";

    if (document.querySelector('#script-cookiebar')) {
      document.querySelector('#script-cookiebar').remove();
    }

    document.body.appendChild(script);
  };

  useEffect(() => {
    if (!cars.length) { return; }
    if (!campaigns) { return; }

    getShowroomData();
  }, [cars, campaigns]);

  useEffect(() => {
    hideZendeskWidget();

    // localize chat widget
    try {
      if (CONFIG.DEBUG) { console.log('Zendesk set to', currentLocale.locale); }
      window.zE('webWidget', 'setLocale', currentLocale.locale);
    } catch(e) {
      if (CONFIG.DEBUG) { console.warn('Zendesk not available'); }
    }
  }, [window.zE, currentLocale])

  useEffect(() => {
    if (!lang) { return; }
    if (!Object.keys(messages).length) { return; }
    if (!cars.length) { return; }
    if (!showroom) { return; }
    if (!meta) { return; }

    setLoading(false);
  }, [ lang, messages, cars, showroom, meta ]);

  useEffect(() => {
    setLang(selectedLanguage);
    getMessages(selectedLanguage);

    const locale = locales.find(({locale}) => locale === selectedLanguage);
    setCurrentLocale(locale);

    getMeta(locale.name);

    // disable cookie check in configurator (iframe)
    // const isConfigurator = window.location.href.indexOf('/configurator/') >= 0;
    // if (!isConfigurator) {
    //   setupCookieScript(locale);
    // }

    getData();

  }, [selectedLanguage]);

  useEffect(() => {
    window.addEventListener('resize', onResize);

    getDealerData();
    setupZendesk();
    getBMS();
    getLegal();
    getHome();
    getStatus();

    setMobile(window.matchMedia(`(max-width: ${CONFIG.MOBILE_WIDTH}px)`).matches);

    return () => {
      window.removeEventListener('resize', onResize);
    }
  }, []);

  const store = {
    loading: { get: loading, set: setLoading },
    language: { locale: lang, set: setLang },
    openMenu: { get: openMenu, set: setOpenMenu },
    mobile,
    cars,
    showroom,
    pages,
    currentLocale,
    showZendesk,
    hideZendeskWidget,
    bms,
    legal,
    locales,
    prefix: currentLocale && locales.length > 1 ? `/${currentLocale.slug}` : '',
    meta,
    dealer,
    clearDealer,
    showMonthlyPrices,
    isB2c,
    otherPlatformUrl: country.other_platform_url,
    home,
    hideToggle,
    country,
    showPromo: bms && isB2c && true, // show promo only for B2C, manual override via true / false
    setupCookieScript,
  };

  return (
    <KiaContext.Provider value={store}>
      <IntlProvider messages={messages} locale={selectedLanguage}>
        { loading ? <Loading page /> : children }
      </IntlProvider>
    </KiaContext.Provider>
  );
}
